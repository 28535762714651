import axios from 'axios';
import { COURSE_LIST_RESET } from '../constants/courseConstants';
import { PROGRAM_LIST_RESET } from '../constants/programConstants';
import { STUDENT_COURSE_LIST_RESET } from '../constants/studentConstants';
import { TEACHER_LIST_RESET } from '../constants/teacherConstants';
// import {
//   CATALOG_DETAILS_RESET,
//   CATALOG_LIST_RESET,
// } from '../constants/catalogConstants';
// import { ORDER_LIST_MY_RESET } from '../constants/orderConstants';
import {
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_EMAIL_VERIFY_FAIL,
  USER_EMAIL_VERIFY_REQUEST,
  USER_EMAIL_VERIFY_RESET,
  USER_EMAIL_VERIFY_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REMOVE_ITEM,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PASSWORD_CHANGE_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_RESET,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_VERIFY_CODE_FAIL,
  USER_PASSWORD_RESET_VERIFY_CODE_REQUEST,
  USER_PASSWORD_RESET_VERIFY_CODE_RESET,
  USER_PASSWORD_RESET_VERIFY_CODE_SUCCESS,
  USER_PASSWORD_RESET_VERIFY_EMAIL_FAIL,
  USER_PASSWORD_RESET_VERIFY_EMAIL_REQUEST,
  USER_PASSWORD_RESET_VERIFY_EMAIL_RESET,
  USER_PASSWORD_RESET_VERIFY_EMAIL_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_RESET,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCCESS,
} from '../constants/userConstants';

import { setAlert } from './alertActions';

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const { data } = await axios.post('/api/auth', { email, password }, config);

    if (data && data.token) {
      const tokenArray = data.token.split(' ');
      if (tokenArray[0] === 'Verify') {
        dispatch({
          type: USER_REGISTER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: data,
        });

        localStorage.setItem('userInfo', JSON.stringify(data));
      }
    }
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo');
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: USER_REGISTER_RESET });
  dispatch({ type: USER_EMAIL_VERIFY_RESET });
  dispatch({ type: USER_PASSWORD_RESET_VERIFY_EMAIL_RESET });
  dispatch({ type: USER_PASSWORD_RESET_VERIFY_CODE_RESET });
  dispatch({ type: USER_PASSWORD_RESET_RESET });
  dispatch({ type: USER_UPDATE_PROFILE_RESET });
  dispatch({ type: USER_LIST_RESET });
  dispatch({ type: USER_UPDATE_RESET });

  dispatch({ type: TEACHER_LIST_RESET });
  dispatch({ type: COURSE_LIST_RESET });
  dispatch({ type: PROGRAM_LIST_RESET });
  dispatch({ type: COURSE_LIST_RESET });
  dispatch({ type: STUDENT_COURSE_LIST_RESET });

  dispatch({ type: USER_PASSWORD_RESET_VERIFY_EMAIL_RESET });
  dispatch({ type: USER_PASSWORD_RESET_VERIFY_CODE_RESET });
  dispatch({ type: USER_PASSWORD_RESET_RESET });
};

export const register =
  (user_email, password, confirmPassword) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const { data } = await axios.post(
        '/api/users/',
        { user_email, password, confirmPassword },
        config
      );
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
      // dispatch({
      //   type: USER_LOGIN_SUCCESS,
      //   payload: data,
      // });

      // localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: error.response,
      });
    }
  };

export const emailVerify =
  (token, verificationCode, passwordReset = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_EMAIL_VERIFY_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const { data } = await axios.post(
        '/api/users/verify',
        { token, verificationCode },
        config
      );
      dispatch({
        type: USER_EMAIL_VERIFY_SUCCESS,
        payload: data,
      });

      if (!passwordReset) {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: data,
        });

        localStorage.setItem('userInfo', JSON.stringify(data));
      }
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: USER_EMAIL_VERIFY_FAIL,
        payload: error.response,
      });
    }
  };

export const sendResetCode =
  (email = '') =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_PASSWORD_RESET_VERIFY_EMAIL_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const { data } = await axios.post(
        '/api/users/send-reset-code',
        { email },
        config
      );
      dispatch({
        type: USER_PASSWORD_RESET_VERIFY_EMAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: USER_PASSWORD_RESET_VERIFY_EMAIL_FAIL,
        payload: error.response,
      });
    }
  };

export const verifyUserEmail = (id, verificationCode) => async (dispatch) => {
  try {
    dispatch({
      type: USER_EMAIL_VERIFY_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const { data } = await axios.post(
      `/api/users/verify/${id}/${verificationCode}`,
      config
    );
    dispatch({
      type: USER_EMAIL_VERIFY_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_EMAIL_VERIFY_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const resetPassword =
  (token, password, confirmPassword) => async (dispatch) => {
    try {
      dispatch({
        type: USER_PASSWORD_RESET_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const { data } = await axios.post(
        `/api/users/reset-password`,
        { token, password, confirmPassword },
        config
      );
      dispatch({
        type: USER_PASSWORD_RESET_SUCCESS,
        payload: data,
      });

      dispatch(
        setAlert(
          'Password reset successful! You will be redirected to login page, please login with your newly set password.',
          'success'
        )
      );

      // dispatch({
      //   type: USER_LOGIN_SUCCESS,
      //   payload: data,
      // });

      // localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_PASSWORD_RESET_FAIL,
        payload: error.response,
      });
    }
  };

export const changePassword =
  (oldPassword, newPassword, confirmPassword) => async (dispatch) => {
    try {
      dispatch({
        type: USER_PASSWORD_CHANGE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const { data } = await axios.post(
        `/api/users/change-password`,
        { oldPassword, newPassword, confirmPassword },
        config
      );
      dispatch({
        type: USER_PASSWORD_CHANGE_SUCCESS,
        payload: data,
      });

      dispatch(setAlert(data, 'success'));
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: USER_PASSWORD_CHANGE_FAIL,
        payload: error.response,
      });
    }
  };

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/users/profile`, user, config);

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listUsers =
  (
    name = '',
    user_email = '',
    is_student = '',
    is_faculty = '',
    is_admin = '',
    is_superadmin = '',
    sortBy = '',
    sortingOrder = '',
    pageNumber = 1,
    pageSize = 50
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `/api/users?name=${name}&user_email=${user_email}&is_student=${is_student}&is_faculty=${is_faculty}&is_admin=${is_admin}&is_superadmin=${is_superadmin}&sortBy=${sortBy}&sortingOrder=${sortingOrder}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      );

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    await axios.delete(`/api/users/${id}`);

    dispatch({
      type: USER_DELETE_SUCCESS,
    });
    dispatch({
      type: USER_LIST_REMOVE_ITEM,
      payload: id,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: USER_DELETE_FAIL,
      payload: error.response,
    });
  }
};

export const createUser =
  (name, user_email, password, confirmPassword, role) => async (dispatch) => {
    try {
      dispatch({
        type: USER_CREATE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const { data } = await axios.post(
        '/api/users/create-user',
        { name, user_email, password, confirmPassword, role },
        config
      );
      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: USER_CREATE_FAIL,
        payload: error.response,
      });
    }
  };

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      'Content-Type': 'application/json',
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/users/${user._id}`, user, config);

    dispatch({
      type: USER_UPDATE_SUCCESS,
    });
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
