import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import HelpIcon from '@mui/icons-material/Help';

import { styled } from '@mui/material/styles';
import { Box, ListItemButton, Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions';

const container = window.document.body;

function SideBar({ drawerWidth, mobileOpen, handleDrawerToggle, history }) {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const { user } = userInfo;

  const onLogOut = () => {
    dispatch(logout());
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItemButton
          onClick={() => history.push('/')}
          selected={history.location.pathname === '/' ? true : false}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={'Home'} />
        </ListItemButton>
        {/* <ListItemButton
          onClick={() => history.push('/dashboard')}
          selected={history.location.pathname === '/dashboard' ? true : false}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={'Dashboard'} />
        </ListItemButton> */}
        {user.is_admin || user.is_exam_admin ? (
          <ListItemButton
            onClick={() => history.push('/evaluation')}
            selected={
              history.location.pathname.includes('/evaluation') ? true : false
            }
          >
            <ListItemIcon>
              <AssignmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Evaluation'} />
          </ListItemButton>
        ) : null}

        <ListItemButton
          onClick={() => history.push('/profile')}
          selected={history.location.pathname === '/profile' ? true : false}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={'Profile'} />
        </ListItemButton>

        {user.is_admin && (
          <ListItemButton
            onClick={() => history.push('/allocations')}
            selected={
              history.location.pathname.includes('/allocations') ? true : false
            }
          >
            <ListItemIcon>
              <AssignmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Allocations'} />
          </ListItemButton>
        )}
        {user.is_faculty && (
          <ListItemButton
            onClick={() => history.push('/evaluation')}
            selected={
              history.location.pathname.includes('/evaluation') ? true : false
            }
          >
            <ListItemIcon>
              <AssignmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Evaluation'} />
          </ListItemButton>
        )}
        {user.is_student && (
          <ListItemButton
            onClick={() => history.push('/evaluation')}
            selected={
              history.location.pathname.includes('/evaluation') ? true : false
            }
          >
            <ListItemIcon>
              <AssignmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Evaluation'} />
          </ListItemButton>
        )}
        {user.is_admin && (
          <ListItemButton
            onClick={() => history.push('/users')}
            selected={
              history.location.pathname.includes('/users') ? true : false
            }
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={'Users'} />
          </ListItemButton>
        )}
        {user.is_admin && (
          <ListItemButton
            onClick={() => history.push('/students')}
            selected={
              history.location.pathname.includes('/students') ? true : false
            }
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={'Students'} />
          </ListItemButton>
        )}
        {user.is_admin && (
          <ListItemButton
            onClick={() => history.push('/teachers')}
            selected={
              history.location.pathname.includes('/teachers') ? true : false
            }
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={'Teachers'} />
          </ListItemButton>
        )}
        {user.is_admin && (
          <ListItemButton
            onClick={() => history.push('/faculties')}
            selected={
              history.location.pathname.includes('/faculties') ? true : false
            }
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary={'Faculty'} />
          </ListItemButton>
        )}
        {user.is_admin && (
          <ListItemButton
            onClick={() => history.push('/departments')}
            selected={
              history.location.pathname.includes('/departments') ? true : false
            }
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary={'Department'} />
          </ListItemButton>
        )}
        {user.is_admin && (
          <ListItemButton
            onClick={() => history.push('/programs')}
            selected={
              history.location.pathname.includes('/programs') ? true : false
            }
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary={'Programs'} />
          </ListItemButton>
        )}
        {user.is_admin && (
          <ListItemButton
            onClick={() => history.push('/courses')}
            selected={
              history.location.pathname.includes('/courses') ? true : false
            }
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary={'Courses'} />
          </ListItemButton>
        )}
        {user.is_admin && (
          <ListItemButton
            onClick={() => history.push('/batches')}
            selected={
              history.location.pathname.includes('/batches') ? true : false
            }
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary={'Batches'} />
          </ListItemButton>
        )}
        {user.is_admin && (
          <ListItemButton
            onClick={() => history.push('/semesters')}
            selected={
              history.location.pathname.includes('/semesters') ? true : false
            }
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary={'Semesters'} />
          </ListItemButton>
        )}
      </List>
      <Divider />
      <List>
      <ListItemButton
            onClick={() => history.push('/semesterResult')}
            selected={
              history.location.pathname.includes('/semesterResult') ? true : false
            }
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary={'Result'} />
          </ListItemButton>
        <a
          href="https://ndub.edu.bd/wp-content/uploads/2022/04/evaluation_tutorial_new.pdf"
          style={{ textDecoration: 'none' }}
        >
          <ListItemButton>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={'Tutorial'} />
          </ListItemButton>
        </a>
        <ListItemButton onClick={onLogOut}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={'Logout'} />
        </ListItemButton>
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default withRouter(SideBar);
