import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

import {
  userLoginReducer,
  userRegisterReducer,
  userPasswordResetVerifyEmailReducer,
  userPasswordResetVerifyCodeReducer,
  userPasswordResetReducer,
  userVerifyEmailReducer,
  userSendResetCodeReducer,
  userPasswordChangeReducer,
  userListReducer,
  userCreateReducer,
  userDeleteReducer,
} from './reducers/userReducers';

import {
  studentBulkRegisterReducer,
  studentRegisterReducer,
} from './reducers/studentReducers';

import { evalMcqQuesListReducer } from './reducers/evalMcqQuesReducers';

import { evalWrittenQuesListReducer } from './reducers/evalWrittenQuesReducers';

import {
  courseListReducer,
  courseDetailsReducer,
  courseAddReducer,
  courseDeleteReducer,
  courseEditReducer,
  courseAddBulkReducer,
} from './reducers/courseReducers';

import {
  evaluationCourseListReducer,
  evaluationCourseListTeacherReducer,
  evaluationCourseReportTeacherReducer,
  evaluationCourseCalculationReducer,
  evaluationOverallResultReducer,
  evaluationOverallResultDetailsReducer,
  evaluationPerQuesAnsReducer,
  evaluationPerQuesWrittenAnsReducer,
  evaluationCourseDetailsReducer,
  evaluationResultPublishReducer,
  evaluationResultMailReducer,
  evaluationResultPdfReducer,
  evaluationStudentListReducer,
} from './reducers/evaluationReducers';

import {
  batchListReducer,
  batchAddReducer,
  batchDeleteReducer,
  batchDetailsReducer,
  batchEditReducer,
} from './reducers/batchReducers';
import {
  semesterListReducer,
  semesterAddReducer,
  semesterEditReducer,
  semesterDeleteReducer,
  semesterDetailsReducer,
} from './reducers/semesterReducers';
import {
  programListReducer,
  programDeleteReducer,
  programAddReducer,
  programDetailsReducer,
  programEditReducer,
} from './reducers/programReducers';
import {
  departmentListReducer,
  departmentAddReducer,
  departmentDeleteReducer,
  departmentDetailsReducer,
  departmentEditReducer,
} from './reducers/departmentReducers';
import {
  teacherListReducer,
  teacherAddReducer,
  teacherDeleteReducer,
  teacherDetailsReducer,
  teacherEditReducer,
} from './reducers/teacherReducers';
import {
  facultyDetailsReducer,
  facultyListReducer,
  facultyAddReducer,
  facultyEditReducer,
  facultyDeleteReducer,
} from './reducers/facultyReducers';
import {
  studentListReducer,
  studentCourseListReducer,
  studentDetailsReducer,
  studentUpdateReducer,
  studentDeleteReducer,
} from './reducers/studentReducers';
import { evalAnsSubmitReducer } from './reducers/evalAnsReducers';

import alertReducer from './reducers/alertReducers';
import {
  allocationCourseListReducer,
  allocationAddBulkReducer,
  allocationAddReducer,
  allocationDeleteReducer,
  allocationEditReducer,
  allocationDetailsReducer,
} from './reducers/allocationReducers';
import { mailSendReducer } from './reducers/mailReducers';
import { smsMultiSendReducer, smsSendReducer } from './reducers/smsReducers';

const reducer = combineReducers({
  evalMcqQuesList: evalMcqQuesListReducer,
  evalWrittenQuesList: evalWrittenQuesListReducer,

  courseList: courseListReducer,
  courseDetails: courseDetailsReducer,
  courseAdd: courseAddReducer,
  courseAddBulk: courseAddBulkReducer,
  courseDelete: courseDeleteReducer,
  courseEdit: courseEditReducer,

  allocationCourseList: allocationCourseListReducer,
  allocationDetails: allocationDetailsReducer,
  allocationAdd: allocationAddReducer,
  allocationAddBulk: allocationAddBulkReducer,
  allocationDelete: allocationDeleteReducer,
  allocationEdit: allocationEditReducer,

  evaluationCourseList: evaluationCourseListReducer,
  evaluationStudentList: evaluationStudentListReducer,
  evaluationCourseListTeacher: evaluationCourseListTeacherReducer,
  evaluationCourseReportTeacher: evaluationCourseReportTeacherReducer,
  evaluationCourseDetails: evaluationCourseDetailsReducer,
  evaluationCourseCalculation: evaluationCourseCalculationReducer,
  evaluationOverallResult: evaluationOverallResultReducer,
  evaluationOverallResultDetails: evaluationOverallResultDetailsReducer,
  evaluationPerQuesAns: evaluationPerQuesAnsReducer,
  evaluationPerQuesWrittenAns: evaluationPerQuesWrittenAnsReducer,
  evaluationResultPublish: evaluationResultPublishReducer,
  evaluationResultMail: evaluationResultMailReducer,
  evaluationResultPdf: evaluationResultPdfReducer,

  batchList: batchListReducer,
  batchAdd: batchAddReducer,
  batchDelete: batchDeleteReducer,
  batchDetails: batchDetailsReducer,
  batchEdit: batchEditReducer,

  semesterList: semesterListReducer,
  semesterAdd: semesterAddReducer,
  semesterDetails: semesterDetailsReducer,
  semesterDelete: semesterDeleteReducer,
  semesterEdit: semesterEditReducer,

  programList: programListReducer,
  programDelete: programDeleteReducer,
  programAdd: programAddReducer,
  programDetails: programDetailsReducer,
  programEdit: programEditReducer,

  departmentList: departmentListReducer,
  departmentAdd: departmentAddReducer,
  departmentDelete: departmentDeleteReducer,
  departmentDetails: departmentDetailsReducer,
  departmentEdit: departmentEditReducer,

  teacherList: teacherListReducer,
  teacherAdd: teacherAddReducer,
  teacherDelete: teacherDeleteReducer,
  teacherDetails: teacherDetailsReducer,
  teacherEdit: teacherEditReducer,

  facultyList: facultyListReducer,
  facultyDetails: facultyDetailsReducer,
  facultyAdd: facultyAddReducer,
  facultyEdit: facultyEditReducer,
  facultyDelete: facultyDeleteReducer,

  studentList: studentListReducer,
  studentDetails: studentDetailsReducer,
  studentUpdate: studentUpdateReducer,
  studentDelete: studentDeleteReducer,
  studentCourseList: studentCourseListReducer,

  evalAnsSubmit: evalAnsSubmitReducer,

  mailSend: mailSendReducer,
  smsSend: smsSendReducer,
  smsMultiSend: smsMultiSendReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userVerifyEmail: userVerifyEmailReducer,
  userSendResetCode: userSendResetCodeReducer,
  userPasswordReset: userPasswordResetReducer,
  userPasswordChange: userPasswordChangeReducer,
  userList: userListReducer,
  userCreate: userCreateReducer,
  userDelete: userDeleteReducer,

  studentRegister: studentRegisterReducer,
  studentBulkRegister: studentBulkRegisterReducer,
  alert: alertReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
