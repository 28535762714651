export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

export const USER_EMAIL_VERIFY_REQUEST = 'USER_EMAIL_VERIFY_REQUEST';
export const USER_EMAIL_VERIFY_SUCCESS = 'USER_EMAIL_VERIFY_SUCCESS';
export const USER_EMAIL_VERIFY_FAIL = 'USER_EMAIL_VERIFY_FAIL';
export const USER_EMAIL_VERIFY_RESET = 'USER_EMAIL_VERIFY_RESET';

export const USER_PASSWORD_RESET_VERIFY_EMAIL_REQUEST =
  'USER_PASSWORD_RESET_VERIFY_EMAIL_REQUEST';
export const USER_PASSWORD_RESET_VERIFY_EMAIL_SUCCESS =
  'USER_PASSWORD_RESET_VERIFY_EMAIL_SUCCESS';
export const USER_PASSWORD_RESET_VERIFY_EMAIL_FAIL =
  'USER_PASSWORD_RESET_VERIFY_EMAIL_FAIL';
export const USER_PASSWORD_RESET_VERIFY_EMAIL_RESET =
  'USER_PASSWORD_RESET_VERIFY_EMAIL_RESET';

export const USER_PASSWORD_RESET_VERIFY_CODE_REQUEST =
  'USER_PASSWORD_RESET_VERIFY_CODE_REQUEST';
export const USER_PASSWORD_RESET_VERIFY_CODE_SUCCESS =
  'USER_PASSWORD_RESET_VERIFY_CODE_SUCCESS';
export const USER_PASSWORD_RESET_VERIFY_CODE_FAIL =
  'USER_PASSWORD_RESET_VERIFY_CODE_FAIL';
export const USER_PASSWORD_RESET_VERIFY_CODE_RESET =
  'USER_PASSWORD_RESET_VERIFY_CODE_RESET';

export const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST';
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_FAIL = 'USER_PASSWORD_RESET_FAIL';
export const USER_PASSWORD_RESET_RESET = 'USER_PASSWORD_RESET_RESET';

export const USER_PASSWORD_CHANGE_REQUEST = 'USER_PASSWORD_CHANGE_REQUEST';
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS';
export const USER_PASSWORD_CHANGE_FAIL = 'USER_PASSWORD_CHANGE_FAIL';
export const USER_PASSWORD_CHANGE_RESET = 'USER_PASSWORD_CHANGE_RESET';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';
export const USER_LIST_REMOVE_ITEM = 'USER_LIST_REMOVE_ITEM';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL';
export const USER_CREATE_RESET = 'USER_CREATE_RESET';
