import { Card, Container, Grid, Typography } from '@mui/material';
import React from 'react';
// import { evalResults } from '../../demo-data/evalResults';
import { data } from '../demo-data/demoData';
import PieChart from './PieChart';

const EvaluationPerQuesAnswer = ({ evaluationPerQuesAnsResult }) => {
  return (
    <Grid container spacing={3}>
      {evaluationPerQuesAnsResult?.map((evalResult, index) => (
        <Grid item xs={12} sm={6} key={evalResult.evaluation_percentage}>
          <Card
            variant="outlined"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="p">
              {index + 1}. {evalResult.mcq_ques_en} (
              {evalResult.mcq_ques_bn.split('।')[0]})
            </Typography>
            <PieChart evalResult={evalResult} />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default EvaluationPerQuesAnswer;
