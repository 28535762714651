import axios from 'axios';

import {
  EVAL_MCQ_QUES_LIST_REQUEST,
  EVAL_MCQ_QUES_LIST_SUCCESS,
  EVAL_MCQ_QUES_LIST_FAIL,
} from '../constants/evalMcqQuesConstants';
// import { setAlert } from './alert';

// Get all evaluation mcq questions
export const listEvalMcqQues = (updateQuesAnsList) => async (dispatch) => {
  try {
    dispatch({ type: EVAL_MCQ_QUES_LIST_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/evaluations/mcq`, config);

    dispatch({
      type: EVAL_MCQ_QUES_LIST_SUCCESS,
      payload: data,
    });
    // dispatch(updateQuesAnsList(data));
  } catch (error) {
    dispatch({
      type: EVAL_MCQ_QUES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// // Get single department
// export const getDepartment = (department_id) => async (dispatch) => {
//   try {
//     dispatch({ type: DEPARTMENT_DETAILS_REQUEST });
//     const { data } = await axios.get(`/api/departments/${department_id}`);
//     dispatch({
//       type: DEPARTMENT_DETAILS_SUCCESS,
//       payload: data,
//     });
//   } catch (err) {
//     console.log(err.response);
//     dispatch({
//       type: DEPARTMENT_DETAILS_FAIL,
//       payload: { msg: err.response.statusText, status: err.response.status },
//     });
//   }
// };

// // Create Department
// export const createDepartment = (formData) => async (dispatch) => {
//   try {
//     dispatch({ type: DEPARTMENT_CREATE_REQUEST });
//     const { data } = await axios.post(`/api/departments/`, formData);

//     dispatch({
//       type: DEPARTMENT_CREATE_SUCCESS,
//       payload: data,
//     });
//     dispatch(setAlert('Department Created', 'success'));
//   } catch (err) {
//     const errors = err.response.data.errors;
//     if (errors) {
//       errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
//     }
//     dispatch({
//       type: DEPARTMENT_CREATE_FAIL,
//       payload: { msg: errors, status: err.response.status },
//     });
//   }
// };

// // Update Department
// export const updateDepartment =
//   (formData, department_id) => async (dispatch) => {
//     console.log(department_id);
//     try {
//       dispatch({ type: DEPARTMENT_UPDATE_REQUEST });
//       const { data } = await axios.put(
//         `/api/departments/${department_id}`,
//         formData
//       );

//       dispatch({
//         type: DEPARTMENT_UPDATE_SUCCESS,
//         payload: data,
//       });
//       dispatch({
//         type: DEPARTMENT_DETAILS_SUCCESS,
//         payload: data,
//       });
//       dispatch(setAlert('Department Updated', 'success'));
//     } catch (err) {
//       const errors = err.response.data.errors;
//       if (errors) {
//         errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
//       }
//       dispatch({
//         type: DEPARTMENT_UPDATE_FAIL,
//         payload: { msg: errors, status: err.response.status },
//       });
//     }
//   };

// // Delete a department
// export const deleteDepartment = (department_id) => async (dispatch) => {
//   try {
//     dispatch({ type: DEPARTMENT_DELETE_REQUEST });
//     const { data } = await axios.delete(`/api/departments/${department_id}`);
//     console.log(data);
//     dispatch({ type: DEPARTMENT_DELETE_SUCCESS });
//     dispatch({
//       type: DEPARTMENT_LIST_REMOVE_ITEM,
//       payload: department_id,
//     });
//     dispatch(setAlert('Department Deleted', 'success'));
//   } catch (err) {
//     const errors = err.response.data.errors;
//     if (errors) {
//       errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
//     }
//     dispatch({
//       type: DEPARTMENT_DELETE_FAIL,
//       payload: { msg: err.response.statusText, status: err.response.status },
//     });
//   }
// };
