import React from 'react';
import { ResponsivePie, ResponsivePieCanvas } from '@nivo/pie';

const PieChart = ({ evalResult }) => {
  let data = [
    {
      id: 'Strongly Disagree',
      label: `SD ${evalResult.strongly_disagree}%`,
      value: evalResult.strongly_disagree,
      color: 'hsl(116, 70%, 50%)',
    },
    {
      id: 'Disagree',
      label: `D ${evalResult.disagree}%`,
      value: evalResult.disagree,
      color: 'hsl(195, 70%, 50%)',
    },
    {
      id: 'Neutral',
      label: `N ${evalResult.neutral}%`,
      value: evalResult.neutral,
      color: 'hsl(77, 70%, 50%)',
    },
    {
      id: 'Agree',
      label: `A ${evalResult.agree}%`,
      value: evalResult.agree,
      color: 'hsl(223, 70%, 50%)',
    },
    {
      id: 'Strongly Agree',
      label: `SA ${evalResult.strongly_agree}%`,
      value: evalResult.strongly_agree,
      color: 'hsl(169, 70%, 50%)',
    },
  ];
  return (
    <div style={{ width: '400px', height: '300px' }}>
      <ResponsivePie
        animate={true}
        pixelRatio={1}
        data={data}
        valueFormat={(value) => `${value}%`}
        margin={{ top: 80, right: 140, bottom: 80, left: 140 }}
        style={{ padding: '100px' }}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 60,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'top-to-bottom',
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default PieChart;
