import { Autocomplete, LinearProgress, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listStudents } from '../../actions/studentActions';

const StudentSearchableDropdown = ({ student, setStudent }) => {
  const dispatch = useDispatch();
  const studentList = useSelector((state) => state.studentList);
  const { loading: loadingStudents, students } = studentList;

  useEffect(() => {
    if (students && students.length <= 50) {
      dispatch(listStudents());
    } else if (!students) {
      dispatch(listStudents());
    }
  }, []);

  // Set default props for students dropdown
  const defaultStudentProps = {
    options: students ? students : [],
    getOptionLabel: (student) => `${student.stu_name} (${student.stu_id})`,
  };

  return (
    <>
      <Autocomplete
        {...defaultStudentProps}
        value={student}
        onChange={(event, newValue) => {
          setStudent(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Student"
            variant="outlined"
            size="small"
          />
        )}
      />
      {loadingStudents && <LinearProgress />}
    </>
  );
};

export default StudentSearchableDropdown;
