import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEvaluationCourseReportTeacher,
  pdfEvaluationResult,
} from '../../actions/evaluationActions';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import StyledTableCell from '../../components/StyledTableCell';
import EvaluationTeacherResultScreen from '../../components/EvaluationPerQuesAnswer';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import queryString from 'query-string';

const EvaluationTeacherReportScreen = ({ match, location, history }) => {
  const dispatch = useDispatch();

  const [teacherName, setTeacherName] = useState('');

  const parsedQuery = queryString.parse(location.search);

  const evaluationCourseReportTeacher = useSelector(
    (state) => state.evaluationCourseReportTeacher
  );
  const {
    loading: loadingReport,
    error: errorReport,
    report,
  } = evaluationCourseReportTeacher;

  const evaluationResultPdf = useSelector((state) => state.evaluationResultPdf);
  const { loading: loadingPdf, error: errorPdf, pdf } = evaluationResultPdf;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  let values = {
    sem_id: parsedQuery.sem_id,
    course_id: parsedQuery.course_id,
    teacher_id: parsedQuery.teacher_id,
    batch_id: parsedQuery.batch_id,
    program_id: '',
    sortingOrder: '',
    sortBy: '',
  };

  useEffect(() => {
    // if (!evaluationCourse) {
    //   dispatch(getEvaluationCourseDetails(values));
    // } else {
    //   setTeacherName(evaluationCourse.teacher_name);
    // }

    if (!report) {
      dispatch(getEvaluationCourseReportTeacher(parsedQuery.report_id));
    } else {
      setTeacherName(report.overall.teacher_name);
    }
  }, [dispatch, report]);

  const onPdf = (id, teacher_name, course_name, sem_name, sem_year) => {
    dispatch(
      pdfEvaluationResult(id, teacher_name, course_name, sem_name, sem_year)
    );
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumb
            linkText={history.location.pathname}
            lastLink={teacherName}
          />
        </Grid>
        {loadingReport && !report ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          <>
            {report?.overall && (
              <Grid item xs={12}>
                <Meta
                  title={report.overall.teacher_name}
                  description={`${report.overall.teacher_name}, ${report.overall.course_name}, ${report.overall.batch_name}, ${report.overall.program_name}, ${report.overall.sem_name}`}
                  keywords={`${report.overall.teacher_name}, ${report.overall.course_name}, ${report.overall.batch_name}, ${report.overall.program_name}, ${report.overall.sem_name}`}
                />
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 12 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {report.overall.program_name}
                    </Typography>
                    <Typography variant="h5" component="div">
                      {report.overall.course_name}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Code: {report.overall.course_code}, Credit:{' '}
                      {report.overall?.course_credit}, Semester:{' '}
                      {report.overall.sem_name}
                    </Typography>
                    <Typography variant="body2">
                      Faculty: {report.overall.teacher_name} <br /> Batch:{' '}
                      {report.overall.batch_name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {report?.overall && (
              <Grid item xs={12}>
                <TableContainer component={Paper} variant="outlined">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={2}>
                          Total Evaluation Result
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Strongly Agree</TableCell>
                        <TableCell>{report.overall.strongly_agree}%</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Agree</TableCell>
                        <TableCell>{report.overall.agree}%</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Neutral</TableCell>
                        <TableCell>{report.overall.neutral}%</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Disagree</TableCell>
                        <TableCell>{report.overall.disagree}%</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Strongly Disagree</TableCell>
                        <TableCell>
                          {report.overall.strongly_disagree}%
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={2}>
                          <Button
                            size="medium"
                            onClick={(e) =>
                              onPdf(
                                report.overall.id,
                                report.overall.teacher_name,
                                report.overall.course_name,
                                report.overall.sem_name,
                                report.overall.sem_year
                              )
                            }
                          >
                            Download Report
                            {loadingPdf && (
                              <>
                                &nbsp;
                                <CircularProgress size={15} thickness={5} />
                              </>
                            )}
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            {report?.details && (
              <Grid item xs={12}>
                <EvaluationTeacherResultScreen
                  evaluationPerQuesAnsResult={report.details}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default EvaluationTeacherReportScreen;
