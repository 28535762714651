import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import { listDepartments } from '../../actions/departmentActions';
import { addProgram } from '../../actions/programActions';

const ProgramAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    program_name: '',
    program_type: '',
    program_url: '',
    program_credit: '',
    dept_id: '',
    program_code: '',
  });

  const programAdd = useSelector((state) => state.programAdd);
  const { loading, success, error, program } = programAdd;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push('/programs');
      }, 2000);
    } else {
      if (!departments) {
        dispatch(listDepartments());
      }
    }
  }, [history, dispatch, success, departments]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDepartmentChange = (event) => {
    setValues({ ...values, dept_id: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(addProgram(values));
  };

  return (
    <Grid container spacing={2}>
      <Meta
        title={'Add Program'}
        description={
          'Add new program to the database of Notre Dame University Bangladesh'
        }
        keywords={'add new program, program'}
      />
      {/* <form onSubmit={(e) => onSubmit(e)} style={{ width: '100%' }}> */}
      <Grid item xs={12}>
        <Typography variant="h4">Add new program</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="outlined"
          type="text"
          value={values.program_name}
          onChange={handleChange('program_name')}
          label="Program Name"
          style={{ width: '100%' }}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          variant="outlined"
          type="text"
          value={values.program_type}
          onChange={handleChange('program_type')}
          label="Program Type"
          style={{ width: '100%' }}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          variant="outlined"
          type="text"
          value={values.program_code}
          onChange={handleChange('program_code')}
          label="Program Code"
          style={{ width: '100%' }}
          size="small"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          variant="outlined"
          type="number"
          value={values.program_credit}
          onChange={handleChange('program_credit')}
          label="Program Credit"
          style={{ width: '100%' }}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          type="text"
          value={values.program_url}
          onChange={handleChange('program_url')}
          label="Program URL"
          style={{ width: '100%' }}
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          select
          value={values.dept_id}
          label="Department"
          onChange={(event) => handleDepartmentChange(event)}
          style={{ width: '100%' }}
          disabled={loadingDepartments}
          size="small"
        >
          {departments?.map((department) => (
            <MenuItem value={department.dept_id} key={department.dept_id}>
              {department.dept_name}
            </MenuItem>
          ))}
        </TextField>
        {loadingDepartments && <LinearProgress />}
      </Grid>

      <Grid item xs={12}>
        <Link to={'/programs'}>
          <Button variant="outlined" disableElevation>
            Back
          </Button>
        </Link>
        <Button
          variant="contained"
          disableElevation
          style={{ marginLeft: '10px' }}
          onClick={(e) => onSubmit(e)}
        >
          Add{' '}
          {loading && (
            <>
              &nbsp;
              <CircularProgress color="white" size={20} thickness={5} />
            </>
          )}
        </Button>
      </Grid>
      {/* </form> */}
      {msg?.length > 0 && <Message />}
    </Grid>
  );
};

export default ProgramAddScreen;
