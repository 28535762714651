import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { listEvalMcqQues } from '../../actions/evalMcqQuesActions';
import EvaluateStep1 from './EvaluateStep1';
import EvaluateStep2 from './EvaluateStep2';
import EvaluateStep3 from './EvaluateStep3';
import EvaluateStep4 from './EvaluateStep4';
import EvaluateStep5 from './EvaluateStep5';

import {
  Card,
  Chip,
  Container,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import { listEvalWrittenQues } from '../../actions/evalWrittenQuesActions';
import { submitEvaluationAnswers } from '../../actions/evalAnsActions';
import { setAlert } from '../../actions/alertActions';
import Message from '../../components/Message';

const Evaluate = ({ match, history }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);

  const [mcqAnswers, setMcqAnswers] = useState({});

  const [writtenAnswers, setWrittenAnswers] = useState({});

  // MCQ Ans Update
  const updateMcqAnswer = (mcq_ques_id, e) => {
    setMcqAnswers({ ...mcqAnswers, [mcq_ques_id]: e.target.value });
    // let foundIndex = mcqAnswers.findIndex((x) => x.mcq_ques_id == mcq_ques_id);
    // if (foundIndex !== -1) {
    //   setMcqAnswers([
    //     ...mcqAnswers.slice(0, foundIndex),
    //     Object.assign({}, mcqAnswers[foundIndex], {
    //       mcq_ques_id,
    //       mcq_ques_ans: e.target.value,
    //     }),
    //     ...mcqAnswers.slice(foundIndex + 1),
    //   ]);
    // } else {
    //   setMcqAnswers([
    //     ...mcqAnswers,
    //     {
    //       mcq_ques_id,
    //       mcq_ques_ans: e.target.value,
    //     },
    //   ]);
    // }
  };

  // Written Ans Update
  const updateWrittenAnswer = (written_ques_id, e) => {
    setWrittenAnswers({ ...writtenAnswers, [written_ques_id]: e.target.value });
    // let foundIndex = writtenAnswers.findIndex(
    //   (x) => x.written_ques_id == written_ques_id
    // );
    // if (foundIndex !== -1) {
    //   if (e.target.value.length > 0) {
    //     setWrittenAnswers([
    //       ...writtenAnswers.slice(0, foundIndex),
    //       Object.assign({}, writtenAnswers[foundIndex], {
    //         written_ques_id,
    //         written_ques_ans: e.target.value,
    //       }),
    //       ...writtenAnswers.slice(foundIndex + 1),
    //     ]);
    //   } else {
    //     let filteredWrittenAnswers = writtenAnswers.filter(
    //       (writtenAnswer) => writtenAnswer.written_ques_id !== written_ques_id
    //     );
    //     setWrittenAnswers([...filteredWrittenAnswers]);
    //   }
    // } else {
    //   setWrittenAnswers([
    //     ...writtenAnswers,
    //     {
    //       written_ques_id,
    //       written_ques_ans: e.target.value,
    //     },
    //   ]);
    // }
  };

  // Proceed to the next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Go back to previous step
  const prevStep = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  const evalMcqQuesList = useSelector((state) => state.evalMcqQuesList);
  const { loading, error, evalMcqQuestions } = evalMcqQuesList;

  const evalWrittenQuesList = useSelector((state) => state.evalWrittenQuesList);
  const {
    loading: loadingWritten,
    error: errorWritten,
    evalWrittenQuestions,
  } = evalWrittenQuesList;

  const evalAnsSubmit = useSelector((state) => state.evalAnsSubmit);
  const { loading: loadingSubmit, error: errorSubmit, success } = evalAnsSubmit;

  const userLogin = useSelector((state) => state.userLogin);
  const {
    userInfo: { student },
  } = userLogin;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const chunkSize = Math.floor(evalMcqQuestions?.length / 4);
  const startPoint = step * chunkSize;

  const evaluationCourseDetails = useSelector(
    (state) => state.evaluationCourseDetails
  );
  const { evaluationCourse } = evaluationCourseDetails;

  useEffect(() => {
    if (success) {
      history.push('/evaluation');
    } else {
      if (!evalMcqQuestions || evalMcqQuestions.length < 1) {
        dispatch(listEvalMcqQues());
      } else {
        let tempEvalMcqAns = {};
        evalMcqQuestions.map((evalMcqQuestion) => {
          tempEvalMcqAns[evalMcqQuestion.mcq_ques_id] = ' ';
        });
        setMcqAnswers({ ...tempEvalMcqAns, ...mcqAnswers });
      }
      if (!evalWrittenQuestions || evalWrittenQuestions.length < 1) {
        dispatch(listEvalWrittenQues());
      } else {
        let tempEvalWrittenAns = {};
        evalWrittenQuestions.map((evalWrittenQuestion) => {
          tempEvalWrittenAns[evalWrittenQuestion.written_ques_id] = '';
        });
        setWrittenAnswers({ ...tempEvalWrittenAns, ...writtenAnswers });
      }
    }
  }, [history, dispatch, evalMcqQuestions, evalWrittenQuestions, success]);

  const onSubmit = () => {
    let missingMcqAnswers = [];
    for (const [key, value] of Object.entries(mcqAnswers)) {
      if (value === ' ') {
        missingMcqAnswers.push(` ${Object.keys(mcqAnswers).indexOf(key) + 1}`);
      }
    }

    let missingWrittenAnswers = [];
    for (const [key, value] of Object.entries(writtenAnswers)) {
      if (value === '') {
        missingWrittenAnswers.push(
          ` ${Object.keys(writtenAnswers).indexOf(key) + 1}`
        );
      }
    }

    if (missingMcqAnswers.length > 0 && missingWrittenAnswers.length > 0) {
      dispatch(
        setAlert(
          `Answer MCQ questions: ${missingMcqAnswers.toString()} & Written questions: ${missingWrittenAnswers.toString()}`,
          'warning'
        )
      );
    } else if (missingMcqAnswers.length > 0) {
      dispatch(
        setAlert(
          `Answer MCQ questions: ${missingMcqAnswers.toString()}`,
          'warning'
        )
      );
    } else if (missingWrittenAnswers.length > 0) {
      dispatch(
        setAlert(
          `Answer written questions: ${missingWrittenAnswers.toString()}`,
          'warning'
        )
      );
    } else
      dispatch(
        submitEvaluationAnswers({
          teacher_id: match.params.teacher_id,
          student_serial_id: student.stu_serial_id,
          student_course_id: match.params.student_course_id,
          mcq: mcqAnswers,
          written: writtenAnswers,
        })
      );
  };

  return (
    <>
      <h2 className="primary-header">Evaluate</h2>
      <div style={{ marginLeft: '5px', marginBottom: '5px' }}>
        {evaluationCourse && (
          <Typography variant="p" display="block">
            {evaluationCourse.course_name}({evaluationCourse.course_code}) -{' '}
            {evaluationCourse.teacher_name}
          </Typography>
        )}

        <Typography variant="p" style={{ fontSize: '12px' }}>
          Step {step + 1} of 5 | Options
        </Typography>
        <Tooltip
          title={
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>Strongly Agree (দৃঢ়ভাবে একমত পোষণ করি)</li>
              <li>Agree (একমত পোষণ করি)</li>
              <li>Neutral (Neither Agree nor Disagree কোন মতামত নেই)</li>
              <li>Disagree (দ্বিমত পোষণ করি)</li>
              <li>Strongly Disagree (দৃঢ়ভাবে দ্বিমত পোষণ করি)</li>
            </ul>
          }
        >
          <IconButton size="small">
            <HelpOutlineIcon size="small" style={{ fontSize: '16px' }} />
          </IconButton>
        </Tooltip>
        <Typography variant="p" style={{ fontSize: '12px' }}>
          | Your identity will always be kept secret (আপনার পরিচয় সর্বদা গোপন
          রাখা হবে)
        </Typography>
      </div>

      {loading || !evalMcqQuestions ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : step === 0 ? (
        <EvaluateStep1
          nextStep={nextStep}
          prevStep={prevStep}
          loading={loading}
          evalMcqQuestions={evalMcqQuestions.slice(
            startPoint,
            startPoint + chunkSize
          )}
          step={step}
          startPoint={startPoint}
          mcqAnswers={mcqAnswers}
          updateMcqAnswer={updateMcqAnswer}
        />
      ) : step === 1 ? (
        <EvaluateStep2
          nextStep={nextStep}
          prevStep={prevStep}
          loading={loading}
          evalMcqQuestions={evalMcqQuestions.slice(
            startPoint,
            startPoint + chunkSize
          )}
          step={step}
          startPoint={startPoint}
          updateMcqAnswer={updateMcqAnswer}
          mcqAnswers={mcqAnswers}
        />
      ) : step === 2 ? (
        <EvaluateStep3
          nextStep={nextStep}
          prevStep={prevStep}
          loading={loading}
          evalMcqQuestions={evalMcqQuestions.slice(
            startPoint,
            startPoint + chunkSize
          )}
          step={step}
          startPoint={startPoint}
          updateMcqAnswer={updateMcqAnswer}
          mcqAnswers={mcqAnswers}
        />
      ) : step === 3 ? (
        <EvaluateStep4
          nextStep={nextStep}
          prevStep={prevStep}
          evalMcqQuestions={evalMcqQuestions.slice(
            startPoint,
            evalMcqQuestions.length
          )}
          step={step}
          startPoint={startPoint}
          updateMcqAnswer={updateMcqAnswer}
          mcqAnswers={mcqAnswers}
        />
      ) : step === 4 ? (
        <EvaluateStep5
          prevStep={prevStep}
          evalWrittenQuestions={evalWrittenQuestions}
          step={step}
          startPoint={startPoint}
          updateWrittenAnswer={updateWrittenAnswer}
          writtenAnswers={writtenAnswers}
          onSubmit={onSubmit}
          loadingSubmit={loadingSubmit}
        />
      ) : (
        <h2 className="primary-text">Error</h2>
      )}
      {msg?.length > 0 && <Message />}
    </>
  );
};

export default Evaluate;
