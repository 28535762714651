import {
  Button,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listCourses } from '../../actions/courseActions';
import { listPrograms } from '../../actions/programActions';
import { deleteBatch, listBatches } from '../../actions/batchActions';
import { listDepartments } from '../../actions/departmentActions';
import StyledTableCell from '../../components/StyledTableCell';
import { Link } from 'react-router-dom';
import { BATCH_EDIT_RESET } from '../../constants/batchConstants';
import Message from '../../components/Message';
import { REMOVE_ALERT } from '../../constants/alertConstants';

const BatchListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    program_id: '',
    batch_name: '',
    dept_id: '',
    sortBy: 'created_at',
    sortingOrder: 'DESC',
  });

  const batchList = useSelector((state) => state.batchList);
  const { loading: loadingBatches, batches, error: errorBatches } = batchList;

  const batchEdit = useSelector((state) => state.batchEdit);
  const { success: successEdit } = batchEdit;

  const programList = useSelector((state) => state.programList);
  const { loading: loadingPrograms, programs } = programList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (successEdit) {
      dispatch({ type: BATCH_EDIT_RESET });
    }

    if (msg) {
      dispatch({ type: REMOVE_ALERT });
    }

    if (!departments) {
      dispatch(listDepartments());
    }

    if (!programs) {
      dispatch(listPrograms());
    }
    dispatch(listBatches());
  }, []);

  useEffect(() => {
    dispatch(
      listBatches(
        values.program_id,
        values.batch_name,
        values.sortingOrder,
        values.sortBy
      )
    );
  }, [
    dispatch,
    values.batch_name,
    values.program_id,
    values.sortingOrder,
    values.sortBy,
  ]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDepartmentChange = (event) => {
    setValues({ ...values, dept_id: event.target.value });
    dispatch(listPrograms(event.target.value));
  };

  const handleProgramChange = (event) => {
    setValues({ ...values, program_id: event.target.value });
    dispatch(listBatches(values));
  };

  const handleDelete = (batch_id) => {
    if (window.confirm('Are you sure you want to delete this batch?')) {
      dispatch(deleteBatch(batch_id));
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            onClick={() => history.push('/batches/add')}
            style={{ width: '100%', height: '100%' }}
            disableElevation
          >
            <AddIcon /> &nbsp; Batch
          </Button>
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            select
            value={values.department}
            label="Department"
            onChange={(event) => handleDepartmentChange(event)}
            style={{ width: '100%' }}
            disabled={loadingDepartments}
            size="small"
          >
            <MenuItem value="">All</MenuItem>
            {departments?.map((department) => (
              <MenuItem value={department.dept_id} key={department.dept_id}>
                {department.dept_name}
              </MenuItem>
            ))}
          </TextField>
          {loadingDepartments && <LinearProgress />}
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            select
            value={values.program}
            label="Program"
            onChange={(event) => handleProgramChange(event)}
            style={{ width: '100%' }}
            disabled={loadingPrograms}
            size="small"
          >
            <MenuItem value="">All</MenuItem>
            {programs?.map((program) => (
              <MenuItem value={program.program_id} key={program.program_id}>
                {program.program_name}
              </MenuItem>
            ))}
          </TextField>
          {loadingPrograms && <LinearProgress />}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            type="text"
            value={values.batch_name}
            onChange={handleChange('batch_name')}
            label="Batch Name"
            style={{ width: '100%' }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortBy}
            label="Sort By"
            onChange={handleChange('sortBy')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="created_at">Creation Time</MenuItem>
            <MenuItem value="updated_at">Update Time</MenuItem>
            <MenuItem value="program_id">Program Name</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortingOrder}
            label="Order"
            onChange={handleChange('sortingOrder')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="ASC">Ascending</MenuItem>
            <MenuItem value="DESC">Descending</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell>SL</StyledTableCell>
                  <StyledTableCell>Batch Name</StyledTableCell>
                  <StyledTableCell align="left">Program Name</StyledTableCell>
                  <StyledTableCell align="left">Program Code</StyledTableCell>
                  <StyledTableCell align="center">Delete</StyledTableCell>
                  <StyledTableCell align="left">Edit/View</StyledTableCell>
                  {/* <TableCell align="left">URL</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {batches?.map((batch, index) => (
                  <TableRow
                    key={batch.batch_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {batch.batch_name}
                    </TableCell>

                    <TableCell align="left">{batch.program_name}</TableCell>
                    <TableCell align="left">{batch.program_code}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDelete(batch.batch_id)}
                      >
                        <DeleteIcon color="error" fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/batches/edit/${batch.batch_id}`}>
                        <IconButton size="small">
                          <ArrowForwardIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {loadingBatches && <LinearProgress />}
          </TableContainer>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default BatchListScreen;
