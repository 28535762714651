export const COURSE_LIST_REQUEST = 'COURSE_LIST_REQUEST';
export const COURSE_LIST_SUCCESS = 'COURSE_LIST_SUCCESS';
export const COURSE_LIST_FAIL = 'COURSE_LIST_FAIL';
export const COURSE_LIST_RESET = 'COURSE_LIST_RESET';
export const COURSE_LIST_REMOVE_ITEM = 'COURSE_LIST_REMOVE_ITEM';

export const COURSE_DETAILS_REQUEST = 'COURSE_DETAILS_REQUEST';
export const COURSE_DETAILS_SUCCESS = 'COURSE_DETAILS_SUCCESS';
export const COURSE_DETAILS_FAIL = 'COURSE_DETAILS_FAIL';
export const COURSE_DETAILS_RESET = 'COURSE_DETAILS_RESET';

export const COURSE_ADD_REQUEST = 'COURSE_ADD_REQUEST';
export const COURSE_ADD_SUCCESS = 'COURSE_ADD_SUCCESS';
export const COURSE_ADD_FAIL = 'COURSE_ADD_FAIL';
export const COURSE_ADD_RESET = 'COURSE_ADD_RESET';

export const COURSE_ADD_BULK_REQUEST = 'COURSE_ADD_BULK_REQUEST';
export const COURSE_ADD_BULK_SUCCESS = 'COURSE_ADD_BULK_SUCCESS';
export const COURSE_ADD_BULK_FAIL = 'COURSE_ADD_BULK_FAIL';
export const COURSE_ADD_BULK_RESET = 'COURSE_ADD_BULK_RESET';

export const COURSE_EDIT_REQUEST = 'COURSE_EDIT_REQUEST';
export const COURSE_EDIT_SUCCESS = 'COURSE_EDIT_SUCCESS';
export const COURSE_EDIT_FAIL = 'COURSE_EDIT_FAIL';
export const COURSE_EDIT_RESET = 'COURSE_EDIT_RESET';

export const COURSE_DELETE_REQUEST = 'COURSE_DELETE_REQUEST';
export const COURSE_DELETE_SUCCESS = 'COURSE_DELETE_SUCCESS';
export const COURSE_DELETE_FAIL = 'COURSE_DELETE_FAIL';
export const COURSE_DELETE_RESET = 'COURSE_DELETE_RESET';
