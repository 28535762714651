import axios from 'axios';

import {
  SEMESTER_LIST_REQUEST,
  SEMESTER_LIST_SUCCESS,
  SEMESTER_LIST_FAIL,
  SEMESTER_ADD_REQUEST,
  SEMESTER_ADD_SUCCESS,
  SEMESTER_ADD_FAIL,
  SEMESTER_DELETE_REQUEST,
  SEMESTER_DELETE_SUCCESS,
  SEMESTER_DELETE_FAIL,
  SEMESTER_LIST_REMOVE_ITEM,
  SEMESTER_DETAILS_REQUEST,
  SEMESTER_DETAILS_SUCCESS,
  SEMESTER_DETAILS_FAIL,
  SEMESTER_EDIT_REQUEST,
  SEMESTER_EDIT_SUCCESS,
  SEMESTER_EDIT_FAIL,
} from '../constants/semesterConstants';
import { setAlert } from './alertActions';

// Get all semesters
export const listSemesters =
  (
    sem_name = '',
    sem_year = '',
    evaluation_open = '',
    sortBy = '',
    sortingOrder = ''
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SEMESTER_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/semesters?sem_name=${sem_name}&sem_year=${sem_year}&evaluation_open=${evaluation_open}&sortBy=${sortBy}&sortingOrder=${sortingOrder}`,
        config
      );

      dispatch({
        type: SEMESTER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SEMESTER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Add new semester
export const addSemester = (values) => async (dispatch) => {
  try {
    dispatch({ type: SEMESTER_ADD_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(`/api/semesters`, values, config);

    dispatch({
      type: SEMESTER_ADD_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Semester added', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: SEMESTER_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Get single semester details
export const getSemesterDetails = (sem_id) => async (dispatch) => {
  try {
    dispatch({ type: SEMESTER_DETAILS_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/semesters/${sem_id}`, config);

    dispatch({
      type: SEMESTER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: SEMESTER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Edit single semester details
export const editSemester = (sem_id, values) => async (dispatch) => {
  try {
    dispatch({ type: SEMESTER_EDIT_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.put(
      `/api/semesters/${sem_id}`,
      values,
      config
    );

    dispatch({
      type: SEMESTER_EDIT_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Semester edited', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: SEMESTER_EDIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Delete a semester
export const deleteSemester = (sem_id) => async (dispatch) => {
  try {
    dispatch({ type: SEMESTER_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/semesters/${sem_id}`);

    dispatch({
      type: SEMESTER_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SEMESTER_LIST_REMOVE_ITEM,
      payload: sem_id,
    });
    dispatch(setAlert('Semester deleted', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: SEMESTER_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
