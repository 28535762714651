import { IconButton } from '@mui/material';
import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuid } from 'uuid';

const StudentBulkTable = ({
  csvArray,
  handleChangeBulk,
  handleAddBulkRow,
  handleRemoveBulkRow,
}) => {
  return (
    <table className="table-style">
      <thead>
        <th className="table-header">Student ID</th>
        <th className="table-header">Name</th>
        <th className="table-header">Email</th>
        <th className="table-header">Phone</th>
      </thead>
      <tbody>
        {csvArray.map((item, index) => (
          <tr key={index} className="table-row">
            <td className="table-data">
              <input
                type="number"
                name="stu_id"
                className="input-style"
                value={item.stu_id}
                onChange={(e) => handleChangeBulk(e, item.id)}
              />
            </td>
            <td className="table-data">
              <input
                type="text"
                name="stu_name"
                className="input-style"
                value={item.stu_name}
                onChange={(e) => handleChangeBulk(e, item.id)}
              />
            </td>
            <td className="table-data">
              <input
                type="text"
                name="stu_email"
                className="input-style"
                value={item.stu_email}
                onChange={(e) => handleChangeBulk(e, item.id)}
              />
            </td>
            <td className="table-data">
              <input
                type="text"
                name="stu_mobile_number"
                className="input-style"
                value={item.stu_mobile_number}
                onChange={(e) => handleChangeBulk(e, item.id)}
              />
            </td>
            <td className="table-data">
              <IconButton
                disabled={item.length === 1}
                onClick={() => handleRemoveBulkRow(item.id)}
                color="danger"
              >
                <CancelIcon />
              </IconButton>
              {csvArray.length - 1 === index && (
                <IconButton
                  onClick={() => handleAddBulkRow(uuid())}
                  color="primary"
                >
                  <AddCircleIcon />
                </IconButton>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default StudentBulkTable;
