import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import {
  addFaculty,
  editFaculty,
  getFacultyDetails,
  listFaculties,
} from '../../actions/facultyActions';

const FacultyEditScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    fac_name: '',
  });

  const facultyDetails = useSelector((state) => state.facultyDetails);
  const {
    loading: loadingDetails,
    success: successDetails,
    error: errorDetails,
    faculty,
  } = facultyDetails;

  const facultyEdit = useSelector((state) => state.facultyEdit);
  const { loading, success, error, faculty: updatedFaculty } = facultyEdit;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push('/faculties');
      }, 2000);
    } else {
      if (!faculty || faculty.fac_id != match.params.id) {
        dispatch(getFacultyDetails(match.params.id));
      } else {
        setValues({
          fac_name: values.fac_name ? values.fac_name : faculty.fac_name,
        });
      }
    }
  }, [history, dispatch, success, faculty]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(editFaculty(match.params.id, values));
  };

  return (
    <Container>
      <Meta
        title={'Edit Faculty'}
        description={
          'Edit existing faculty in the database of Notre Dame University Bangladesh'
        }
        keywords={'edit faculty, faculty'}
      />
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Edit faculty</Typography>
          </Grid>
          {loadingDetails ? (
            <>
              <Grid item xs={12}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginTop: '-15px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type="text"
                  value={values.fac_name}
                  onChange={handleChange('fac_name')}
                  label="Faculty Name"
                  style={{ width: '100%' }}
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <Link to={'/faculties'}>
                  <Button variant="outlined" disableElevation type="submit">
                    Back
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disableElevation
                  style={{ marginLeft: '10px' }}
                  type="submit"
                >
                  Update{' '}
                  {loading && (
                    <>
                      &nbsp;
                      <CircularProgress color="white" size={20} thickness={5} />
                    </>
                  )}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default FacultyEditScreen;
