import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid, Button, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const EvaluateStep3 = ({
  loading,
  evalMcqQuestions,
  step,
  startPoint,
  nextStep,
  prevStep,
  updateMcqAnswer,
  mcqAnswers,
}) => {
  return (
    <Grid container spacing={2} style={{ marginTop: '5px' }}>
      {evalMcqQuestions.map((evalMcqQuestion, index) => (
        <Grid item xs={12} sm={12} key={evalMcqQuestion.mcq_ques_id}>
          <FormControl component="fieldset">
            <FormLabel>{`${index + startPoint + 1}. ${
              evalMcqQuestion.mcq_ques_en
            } (${evalMcqQuestion.mcq_ques_bn})`}</FormLabel>
            <RadioGroup
              row
              value={mcqAnswers[evalMcqQuestion.mcq_ques_id] ?? ' '}
              onChange={(e) => updateMcqAnswer(evalMcqQuestion.mcq_ques_id, e)}
            >
              <FormControlLabel
                value="1"
                control={<Radio size="small" />}
                label="Strongly Agree"
              />
              <FormControlLabel
                value="2"
                control={<Radio size="small" />}
                label="Agree"
              />
              <FormControlLabel
                value="3"
                control={<Radio size="small" />}
                label="Neutral"
              />
              <FormControlLabel
                value="4"
                control={<Radio size="small" />}
                label="Disagree"
              />
              <FormControlLabel
                value="5"
                control={<Radio size="small" />}
                label="Strongly Disagree"
              />
            </RadioGroup>
          </FormControl>
          {evalMcqQuestions.length !== index + 1 && (
            <Divider style={{ display: 'block', marginTop: '10px' }} />
          )}
        </Grid>
      ))}
      <Grid item style={{ marginTop: '5px' }}>
        <Button
          variant="outlined"
          disableElevation
          size="large"
          type="submit"
          onClick={prevStep}
        >
          Back
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="contained"
          disableElevation
          size="large"
          type="submit"
          onClick={nextStep}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default EvaluateStep3;
