export const TEACHER_LIST_REQUEST = 'TEACHER_LIST_REQUEST';
export const TEACHER_LIST_SUCCESS = 'TEACHER_LIST_SUCCESS';
export const TEACHER_LIST_FAIL = 'TEACHER_LIST_FAIL';
export const TEACHER_LIST_RESET = 'TEACHER_LIST_RESET';
export const TEACHER_LIST_REMOVE_ITEM = 'TEACHER_LIST_REMOVE_ITEM';

export const TEACHER_DETAILS_REQUEST = 'TEACHER_DETAILS_REQUEST';
export const TEACHER_DETAILS_SUCCESS = 'TEACHER_DETAILS_SUCCESS';
export const TEACHER_DETAILS_FAIL = 'TEACHER_DETAILS_FAIL';
export const TEACHER_DETAILS_RESET = 'TEACHER_DETAILS_RESET';

export const TEACHER_EDIT_REQUEST = 'TEACHER_EDIT_REQUEST';
export const TEACHER_EDIT_SUCCESS = 'TEACHER_EDIT_SUCCESS';
export const TEACHER_EDIT_FAIL = 'TEACHER_EDIT_FAIL';
export const TEACHER_EDIT_RESET = 'TEACHER_EDIT_RESET';

export const TEACHER_ADD_REQUEST = 'TEACHER_ADD_REQUEST';
export const TEACHER_ADD_SUCCESS = 'TEACHER_ADD_SUCCESS';
export const TEACHER_ADD_FAIL = 'TEACHER_ADD_FAIL';
export const TEACHER_ADD_RESET = 'TEACHER_ADD_RESET';

export const TEACHER_DELETE_REQUEST = 'TEACHER_DELETE_REQUEST';
export const TEACHER_DELETE_SUCCESS = 'TEACHER_DELETE_SUCCESS';
export const TEACHER_DELETE_FAIL = 'TEACHER_DELETE_FAIL';
export const TEACHER_DELETE_RESET = 'TEACHER_DELETE_RESET';
