export const STUDENT_REGISTER_REQUEST = 'STUDENT_REGISTER_REQUEST';
export const STUDENT_REGISTER_SUCCESS = 'STUDENT_REGISTER_SUCCESS';
export const STUDENT_REGISTER_FAIL = 'STUDENT_REGISTER_FAIL';
export const STUDENT_REGISTER_RESET = 'STUDENT_REGISTER_RESET';

export const STUDENT_BULK_REGISTER_REQUEST = 'STUDENT_BULK_REGISTER_REQUEST';
export const STUDENT_BULK_REGISTER_SUCCESS = 'STUDENT_BULK_REGISTER_SUCCESS';
export const STUDENT_BULK_REGISTER_FAIL = 'STUDENT_BULK_REGISTER_FAIL';
export const STUDENT_BULK_REGISTER_RESET = 'STUDENT_BULK_REGISTER_RESET';

export const STUDENT_DETAILS_REQUEST = 'STUDENT_DETAILS_REQUEST';
export const STUDENT_DETAILS_SUCCESS = 'STUDENT_DETAILS_SUCCESS';
export const STUDENT_DETAILS_FAIL = 'STUDENT_DETAILS_FAIL';
export const STUDENT_DETAILS_RESET = 'STUDENT_DETAILS_RESET';

export const STUDENT_LIST_REQUEST = 'STUDENT_LIST_REQUEST';
export const STUDENT_LIST_SUCCESS = 'STUDENT_LIST_SUCCESS';
export const STUDENT_LIST_FAIL = 'STUDENT_LIST_FAIL';
export const STUDENT_LIST_RESET = 'STUDENT_LIST_RESET';

export const STUDENT_COURSE_LIST_REQUEST = 'STUDENT_COURSE_LIST_REQUEST';
export const STUDENT_COURSE_LIST_SUCCESS = 'STUDENT_COURSE_LIST_SUCCESS';
export const STUDENT_COURSE_LIST_FAIL = 'STUDENT_COURSE_LIST_FAIL';
export const STUDENT_COURSE_LIST_RESET = 'STUDENT_COURSE_LIST_RESET';

export const STUDENT_DELETE_REQUEST = 'STUDENT_DELETE_REQUEST';
export const STUDENT_DELETE_SUCCESS = 'STUDENT_DELETE_SUCCESS';
export const STUDENT_DELETE_FAIL = 'STUDENT_DELETE_FAIL';
export const STUDENT_LIST_REMOVE_ITEM = 'STUDENT_LIST_REMOVE_ITEM';

export const STUDENT_UPDATE_REQUEST = 'STUDENT_UPDATE_REQUEST';
export const STUDENT_UPDATE_SUCCESS = 'STUDENT_UPDATE_SUCCESS';
export const STUDENT_UPDATE_FAIL = 'STUDENT_UPDATE_FAIL';
export const STUDENT_UPDATE_RESET = 'STUDENT_UPDATE_RESET';
