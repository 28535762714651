import React, { useState } from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import Meta from '../components/Meta';

const SemesterResultScreen = () => {
  const [openPDF, setOpenPDF] = useState(null); // Tracks the currently opened PDF

  // Define the file paths for the 5 PDFs
  const pdfLinks = [
    `${process.env.PUBLIC_URL}/fall-2024-semester-result/BBA-01112025203806.pdf`,
    `${process.env.PUBLIC_URL}/fall-2024-semester-result/CSE Final Result,Fall 2024.pdf`,
    `${process.env.PUBLIC_URL}/fall-2024-semester-result/ELL Final Results Fall 2024.pdf`,
    `${process.env.PUBLIC_URL}/fall-2024-semester-result/LLB Final Resutl Fall 2024.pdf`,
    `${process.env.PUBLIC_URL}/fall-2024-semester-result/MICR Final Results Fall 2024.pdf`,
  ];

  const togglePDF = (pdfNum) => {
    setOpenPDF(openPDF === pdfNum ? null : pdfNum);
  };

  return (
    <Container>
      <Meta
        title="Semester Result"
        description="Semester Results available at Notre Dame University Bangladesh"
        keywords="ndub semester result"
      />

      {/* Page Heading */}
      <Typography
        variant="h4"
        align="center"
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          fontWeight: 'bold',
          color: '#001f4d', // Dark navy blue
        }}
      >
        Fall Semester 2024 Final Exam Results
      </Typography>
      <Typography
        variant="body1"
        align="center"
        style={{
          marginBottom: '30px',
          fontWeight: 'bold',
          color: '#33475b', // Subtle navy shade for the description
        }}
      >
        Select a program below to view the semester results.
      </Typography>

      {/* Buttons and PDF Viewer */}

      <Grid container spacing={2}>
        {['BBA', 'CSE', 'English', 'LLB', 'Microbiology'].map((buttonText, index) => (
          <Grid item xs={12} key={index}>
            <Button
              onClick={() => togglePDF(index)}
              variant="contained"
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              {openPDF === index ? `Close ${buttonText}` : `${buttonText}`}
            </Button>
            {openPDF === index && (
              <div style={{ marginTop: '10px', position: 'relative' }}>
                <object
                  data={pdfLinks[index]}
                  type="application/pdf"
                  style={{
                    width: '100%',
                    height: '80vh', // Adjust height for viewport
                    border: 'none',
                  }}
                  aria-label={`${buttonText} PDF`}
                >
                  <p>
                    Your browser does not support PDFs. You can{' '}
                    <a
                      href={pdfLinks[index]}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: '#1E90FF', // Blue color
                        fontWeight: 'bold', // Bold text
                        textDecoration: 'underline', // Underlined link
                      }}
                    >
                      download the PDF
                    </a>{' '}
                    instead.
                  </p>
                </object>
              </div>
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SemesterResultScreen;
