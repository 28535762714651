import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CourseSearchableDropdown from '../../components/dropdowns/CourseSearchableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { listCourses } from '../../actions/courseActions';
import TeacherSearchableDropdown from '../../components/dropdowns/TeacherSearchableDropdown';
import { listTeachers } from '../../actions/teacherActions';
import { listStudents } from '../../actions/studentActions';
import { listSemesters } from '../../actions/semesterActions';
import StudentSearchableDropdown from '../../components/dropdowns/StudentSearchableDropdown';
import SemesterSearchableDropdown from '../../components/dropdowns/SemesterSearchableDropdown';
import {
  addAllocation,
  addBulkAllocation,
} from '../../actions/allocationActions';
import { Link } from 'react-router-dom';
import Message from '../../components/Message';
import BatchSearchableDropdown from '../../components/dropdowns/BatchSearchableDropdown';
import { listBatches } from '../../actions/batchActions';

const CourseAllocationAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const courseList = useSelector((state) => state.courseList);
  const { courses } = courseList;

  const teacherList = useSelector((state) => state.teacherList);
  const { teachers } = teacherList;

  const batchList = useSelector((state) => state.batchList);
  const { batches } = batchList;

  const semesterList = useSelector((state) => state.semesterList);
  const { semesters } = semesterList;

  const allocationAddBulk = useSelector((state) => state.allocationAddBulk);
  const {
    allocation,
    loading: loadingAllocationAddBulk,
    success,
  } = allocationAddBulk;

  const [batch, setBatch] = useState(null);
  const [semester, setSemester] = useState(null);

  const [allocations, setAllocations] = useState([
    {
      id: uuid(),
      course_id: '',
      teacher_id: '',
    },
  ]);

  const handleChangeCourse = (c, id) => {
    const newAllocation = allocations.map((i) => {
      if (id === i.id) {
        c === null ? (i.course_id = '') : (i.course_id = c.course_id);
      }
      return i;
    });
    setAllocations(newAllocation);
  };

  const handleChangeTeacher = (t, id) => {
    const newAllocation = allocations.map((i) => {
      if (id === i.id) {
        t === null ? (i.teacher_id = '') : (i.teacher_id = t.teacher_id);
      }
      return i;
    });
    setAllocations(newAllocation);
  };

  const handleAddAllocations = () => {
    setAllocations([
      ...allocations,
      {
        id: uuid(),
        course_id: '',
        dept_id: '',
        teacher_id: '',
      },
    ]);
  };

  const handleRemoveAllocations = (id) => {
    let values = [...allocations];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setAllocations([...values]);
  };

  useEffect(() => {
    if (!courses) {
      dispatch(listCourses());
    }
    if (!teachers) {
      dispatch(listTeachers());
    }
    if (!batches) {
      dispatch(listBatches());
    }
    if (!semesters) {
      dispatch(listSemesters());
    }
  }, []);

  // useEffect(() => {
  //   if (success) {
  //     setTimeout(() => {
  //       history.push('/allocations');
  //     }, 5000);
  //   }
  // }, [success]);

  const onSubmit = () => {
    if (!semester || !batch || allocations[0].course_id === '') {
      window.alert('Please add allocations!');
    } else
      dispatch(
        addBulkAllocation({
          semester_id: semester.sem_id,
          batch_id: batch.batch_id,
          details: allocations,
        })
      );
  };

  return (
    <div>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Add new course allocation for whole batch
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <BatchSearchableDropdown batch={batch} setBatch={setBatch} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SemesterSearchableDropdown
            semester={semester}
            setSemester={setSemester}
          />
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" style={{ padding: '10px 20px 10px 20px' }}>
            <p style={{ margin: 0 }}>Add Courses & Teachers</p>
            {allocations.map((allocation, index) => (
              <Grid container spacing={3} key={allocation.id} mb={1}>
                <Grid item xs={12} sm={5}>
                  <CourseSearchableDropdown
                    setCourse={handleChangeCourse}
                    id={allocation.id}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TeacherSearchableDropdown
                    setTeacher={handleChangeTeacher}
                    id={allocation.id}
                  />
                </Grid>

                <Grid item xs={12} sm={2} align="left">
                  <IconButton
                    disabled={allocations.length === 1}
                    onClick={() => handleRemoveAllocations(allocation.id)}
                    color="danger"
                  >
                    <CancelIcon />
                  </IconButton>
                  {allocations.length - 1 === index && (
                    <IconButton
                      onClick={() => handleAddAllocations()}
                      color="primary"
                    >
                      <AddCircleIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Link to={'/allocations'}>
            <Button variant="outlined" disableElevation>
              Back
            </Button>
          </Link>
          <Button
            variant="contained"
            disableElevation
            style={{ marginLeft: '10px' }}
            onClick={(e) => onSubmit(e)}
          >
            Add{' '}
            {loadingAllocationAddBulk && (
              <>
                &nbsp;
                <CircularProgress color="white" size={20} thickness={5} />
              </>
            )}
          </Button>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </div>
  );
};

export default CourseAllocationAddScreen;
