import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';

import NDUB_LOGO from '../img/NDUB_Logo.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import { register } from '../actions/userActions';
import { useEffect } from 'react';
import setAuthToken from '../utils/setAuthToken';
import {
  USER_EMAIL_VERIFY_RESET,
  USER_LOGIN_RESET,
  USER_PASSWORD_RESET_RESET,
  USER_PASSWORD_RESET_VERIFY_CODE_RESET,
  USER_PASSWORD_RESET_VERIFY_EMAIL_RESET,
  USER_REGISTER_RESET,
} from '../constants/userConstants';

const RegisterScreen = ({ history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, userInfo } = userRegister;

  useEffect(() => {
    if (userInfo) {
      // setAuthToken(userInfo.token);
      history.push(`/register/verify?token=${userInfo.token}`);
    }
  }, [history, userInfo]);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const [values, setValues] = React.useState({
    user_email: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      register(
        values.user_email.trim(),
        values.password,
        values.confirmPassword
      )
    );
  };

  const resetStuffs = () => {
    dispatch({ type: USER_LOGIN_RESET });
    dispatch({ type: USER_REGISTER_RESET });
    dispatch({ type: USER_PASSWORD_RESET_RESET });
    dispatch({ type: USER_PASSWORD_RESET_VERIFY_CODE_RESET });
    dispatch({ type: USER_PASSWORD_RESET_VERIFY_EMAIL_RESET });
    dispatch({ type: USER_EMAIL_VERIFY_RESET });
  };

  return (
    <Container>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="login-container"
      >
        {/* <Grid item xs={12} sm={6} className="login-art-container">
          <img src={NDUB_LOGO} alt="NDUB Logo" className="login-art" />
          <h2 className="primary-header">
            Welcome to <br /> NDUB Student Portal
          </h2>
        </Grid> */}
        <Grid
          item
          xs={12}
          sm={6}
          className="login-art-container"
          direction={'column'}
        >
          <img src={NDUB_LOGO} alt="NDUB Logo" className="login-art" />
          <h2 className="primary-header">NDUB Student Portal</h2>
        </Grid>
        <Grid item xs={12} sm={6} className="grid-center">
          <Card variant="outlined" className="login-form-container">
            <h2 className="primary-header mb-20">Register</h2>
            <form onSubmit={(e) => onSubmit(e)}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={values.user_email}
                onChange={handleChange('user_email')}
                label="NDUB Email"
                style={{ width: '100%', marginBottom: '20px' }}
              />

              <TextField
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Password"
              />
              <TextField
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                type={values.showConfirmPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                onChange={handleChange('confirmPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Confirm Password"
              />
              <i className="fas fa-user" style={{ color: '#000' }} />
              <Button
                variant="contained"
                disableElevation
                size="large"
                style={{ width: '100%', marginBottom: '20px' }}
                type="submit"
              >
                Register
                {loading && (
                  <>
                    &nbsp;
                    <CircularProgress color="white" size={20} thickness={5} />
                  </>
                )}
              </Button>
            </form>
            <Typography variant="p">
              Already have an account?{' '}
              <Link
                to="/login"
                onClick={() => resetStuffs()}
                style={{ textDecoration: 'underline' }}
              >
                Login
              </Link>{' '}
              | Need help?{' '}
              <Link to="/help" style={{ textDecoration: 'underline' }}>
                Help
              </Link>
            </Typography>
          </Card>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default RegisterScreen;
