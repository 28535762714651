import {
  Button,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDepartment,
  listDepartments,
} from '../../actions/departmentActions';
import Meta from '../../components/Meta';
import StyledTableCell from '../../components/StyledTableCell';

import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import {
  DEPARTMENT_ADD_RESET,
  DEPARTMENT_EDIT_RESET,
} from '../../constants/departmentConstants';
import Message from '../../components/Message';

const DepartmentListScreen = ({ history }) => {
  const dispatch = useDispatch();
  const departmentList = useSelector((state) => state.departmentList);
  const { loading, error, departments } = departmentList;

  const departmentAdd = useSelector((state) => state.departmentAdd);
  const { success: successAdd } = departmentAdd;

  const departmentEdit = useSelector((state) => state.departmentEdit);
  const { success: successEdit } = departmentEdit;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (successAdd) {
      dispatch({ type: DEPARTMENT_ADD_RESET });
    }
    if (successEdit) {
      dispatch({ type: DEPARTMENT_EDIT_RESET });
    }

    dispatch(listDepartments());
  }, [dispatch, successAdd, successEdit]);

  const handleDelete = (dept_id) => {
    if (window.confirm('Are you sure you want to delete this department?')) {
      dispatch(deleteDepartment(dept_id));
    }
  };

  return (
    <Container>
      <Grid container spacing={3} mb={2}>
        <Meta
          title={'Department'}
          description={'Departments of Notre Dame University Bangladesh'}
          keywords={'ndub departments, add ndub departments, ndub department'}
        />
        {/* <h1 className="primary-header">Department List Screen</h1> <br /> */}
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            onClick={() => history.push('/departments/add')}
            // size="small"
            style={{ width: '100%', height: '100%' }}
            disableElevation
          >
            <AddIcon /> &nbsp; Department
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="course list table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>SL</StyledTableCell>
              <StyledTableCell>Department Name</StyledTableCell>
              <StyledTableCell align="left">Code</StyledTableCell>
              <StyledTableCell align="left">URL</StyledTableCell>
              <StyledTableCell align="left">Faculty</StyledTableCell>
              <StyledTableCell align="center">Delete</StyledTableCell>
              <StyledTableCell align="center">View/Edit</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departments?.map((department, index) => (
              <TableRow
                key={department.dept_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {department.dept_name}
                </TableCell>
                <TableCell align="left">{department.dept_code}</TableCell>
                <TableCell align="left">
                  <a
                    href={department.dept_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton aria-label="delete" size="small">
                      <OpenInNewIcon color="primary" fontSize="small" />
                    </IconButton>
                  </a>
                </TableCell>
                <TableCell align="left">{department.fac_name}</TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(department.dept_id)}
                  >
                    <DeleteIcon color="error" fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Link to={`/departments/edit?dept_id=${department.dept_id}`}>
                    <IconButton aria-label="delete" size="small">
                      <ArrowForwardIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
                {/* <TableCell align="left">{course.course_url}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
      </TableContainer>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default DepartmentListScreen;
