import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import { listFaculties } from '../../actions/facultyActions';
import { editProgram, getProgramDetails } from '../../actions/programActions';
import { listDepartments } from '../../actions/departmentActions';

const ProgramEditScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    program_name: '',
    program_type: '',
    program_url: '',
    program_credit: '',
    dept_id: '',
    program_code: '',
  });

  const programDetails = useSelector((state) => state.programDetails);
  const { loading, success, error, program } = programDetails;

  const programEdit = useSelector((state) => state.programEdit);
  const {
    loading: loadingEdit,
    success: successEdit,
    error: errorEdit,
    program: editedprogram,
  } = programEdit;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingFaculties,
    error: errorFaculties,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (successEdit) {
      setTimeout(() => {
        history.push('/programs');
      }, 2000);
    } else {
      if (!program || program.program_id != match.params.id) {
        dispatch(getProgramDetails(match.params.id));
      } else {
        setValues({ ...program });
      }
      if (!departments) {
        dispatch(listDepartments());
      }
    }
  }, [history, dispatch, departments, program, match.params.id, successEdit]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleFacultyChange = (event) => {
    setValues({ ...values, fac_id: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(editProgram(match.params.id, values));
  };

  return (
    <Grid container spacing={2}>
      <Meta
        title={'Edit program'}
        description={
          'Edit an existing program in the database of Notre Dame University Bangladesh'
        }
        keywords={'edit program, ndub program'}
      />
      {/* <form onSubmit={(e) => onSubmit(e)} style={{ width: '100%' }}> */}
      <Grid item xs={12}>
        <Typography variant="h4">Edit program</Typography>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <Typography
            animation="wave"
            component="div"
            variant={'h3'}
            style={{ marginBottom: '-25px' }}
          >
            <Skeleton />
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            type="text"
            value={loading ? <Skeleton variant="text" /> : values.program_name}
            onChange={handleChange('program_name')}
            label="program Name"
            style={{ width: '100%' }}
            size="small"
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        {loading ? (
          <Typography
            animation="wave"
            component="div"
            variant={'h3'}
            style={{ marginBottom: '-25px' }}
          >
            <Skeleton />
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            type="text"
            value={values.program_type}
            onChange={handleChange('program_type')}
            label="Program Type"
            style={{ width: '100%' }}
            size="small"
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        {loading ? (
          <Typography
            animation="wave"
            component="div"
            variant={'h3'}
            style={{ marginBottom: '-25px' }}
          >
            <Skeleton />
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            type="text"
            value={values.program_code}
            onChange={handleChange('program_code')}
            label="Program Code"
            style={{ width: '100%' }}
            size="small"
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        {loading ? (
          <Typography
            animation="wave"
            component="div"
            variant={'h3'}
            style={{ marginBottom: '-25px' }}
          >
            <Skeleton />
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            type="number"
            value={values.program_credit}
            onChange={handleChange('program_credit')}
            label="Program Credit"
            style={{ width: '100%' }}
            size="small"
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Typography
            animation="wave"
            component="div"
            variant={'h3'}
            style={{ marginBottom: '-25px' }}
          >
            <Skeleton />
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            type="text"
            value={values.program_url}
            onChange={handleChange('program_url')}
            label="Program URL"
            style={{ width: '100%' }}
            size="small"
          />
        )}
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <Typography animation="wave" component="div" variant={'h3'}>
            <Skeleton />
          </Typography>
        ) : (
          <TextField
            select
            value={values.dept_id}
            label="Department"
            onChange={(event) => handleFacultyChange(event)}
            style={{ width: '100%' }}
            disabled={loadingFaculties}
            size="small"
          >
            {departments?.map((department) => (
              <MenuItem value={department.dept_id} key={department.dept_id}>
                {department.dept_name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      <Grid item xs={12}>
        <Link to={'/programs'}>
          <Button
            variant="outlined"
            disableElevation
            // size="large"
            type="submit"
          >
            Back
          </Button>
        </Link>
        <Button
          variant="contained"
          disableElevation
          // size="large"
          style={{ marginLeft: '10px' }}
          onClick={(e) => onSubmit(e)}
        >
          Submit{' '}
          {loadingEdit && (
            <>
              &nbsp;
              <CircularProgress color="white" size={20} thickness={5} />
            </>
          )}
        </Button>
      </Grid>
      {/* </form> */}
      {msg?.length > 0 && <Message />}
    </Grid>
  );
};

export default ProgramEditScreen;
