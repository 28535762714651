import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Meta from '../components/Meta';
import { USER_REGISTER_RESET } from '../constants/userConstants';
import NDUB_LOGO from '../img/NDUB_Logo.png';

const HomeScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: USER_REGISTER_RESET });
  }, []);
  return (
    <div>
      <Meta />
      <img src={NDUB_LOGO} alt="NDUB Logo" className="login-art" />
      <Typography variant="h2">Welcome to NDUB Student Portal</Typography>
      <Link to="/evaluation">
        <Typography paragraph>
          Currently, the evaluation portion is online. More functionalities will
          be added gradually.
        </Typography>
      </Link>
    </div>
  );
};

export default HomeScreen;
