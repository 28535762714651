import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listDepartments } from '../../actions/departmentActions';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import {
  addTeacher,
  editTeacher,
  getTeacherDetails,
} from '../../actions/teacherActions';

const TeacherAddScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    teacher_name: '',
    teacher_designation: '',
    teacher_email: '',
    dept_id: '',
  });

  const teacherDetails = useSelector((state) => state.teacherDetails);
  const { loading: loadingDetails, success, error, teacher } = teacherDetails;

  const teacherEdit = useSelector((state) => state.teacherEdit);
  const {
    loading,
    success: successEdit,
    error: errorEdit,
    teacher: updatedTeacher,
  } = teacherEdit;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (successEdit) {
      setTimeout(() => {
        history.push('/teachers');
      }, 2000);
    } else {
      if (!teacher || teacher.teacher_id != match.params.id) {
        dispatch(getTeacherDetails(match.params.id));
      } else {
        setValues({
          teacher_name: values.teacher_name
            ? values.teacher_name
            : teacher.teacher_name,
          teacher_designation: values.teacher_designation
            ? values.teacher_designation
            : teacher.teacher_designation,
          teacher_email: values.teacher_email
            ? values.teacher_email
            : teacher.teacher_email,
          dept_id: values.dept_id ? values.dept_id : teacher.dept_id,
        });
      }
      if (!departments) {
        dispatch(listDepartments());
      }
    }
  }, [dispatch, successEdit, match.params.id, history, teacher, departments]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDepartmentChange = (event) => {
    setValues({ ...values, dept_id: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(editTeacher(match.params.id, values));
  };

  return (
    <Container>
      <Meta
        title={'Edit Teacher'}
        description={
          'Edit existing teacher in the database of Notre Dame University Bangladesh'
        }
        keywords={'edit teacher, update teacher'}
      />
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Edit teacher</Typography>
          </Grid>
          {loadingDetails ? (
            <>
              <Grid item xs={12} sm={6}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  type="text"
                  value={values.teacher_name}
                  onChange={handleChange('teacher_name')}
                  label="Teacher Name"
                  style={{ width: '100%' }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  type="text"
                  value={values.teacher_designation}
                  onChange={handleChange('teacher_designation')}
                  label="Designation"
                  style={{ width: '100%' }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  type="text"
                  value={values.teacher_email}
                  onChange={handleChange('teacher_email')}
                  label="Email"
                  style={{ width: '100%' }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  value={values.dept_id}
                  label="Department"
                  onChange={(event) => handleDepartmentChange(event)}
                  style={{ width: '100%' }}
                  disabled={loadingDepartments}
                  size="small"
                >
                  {departments?.map((department) => (
                    <MenuItem
                      value={department.dept_id}
                      key={department.dept_id}
                    >
                      {department.dept_name}
                    </MenuItem>
                  ))}
                </TextField>
                {loadingDepartments && <LinearProgress />}
              </Grid>
              <Grid item xs={12}>
                <Link to={'/teachers'}>
                  <Button variant="outlined" disableElevation>
                    Back
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disableElevation
                  type="submit"
                  style={{ marginLeft: '10px' }}
                >
                  Update{' '}
                  {loading && (
                    <>
                      &nbsp;
                      <CircularProgress color="white" size={20} thickness={5} />
                    </>
                  )}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default TeacherAddScreen;
