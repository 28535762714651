import {
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_RESET,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_EMAIL_VERIFY_FAIL,
  USER_EMAIL_VERIFY_REQUEST,
  USER_EMAIL_VERIFY_RESET,
  USER_EMAIL_VERIFY_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REMOVE_ITEM,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_RESET,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PASSWORD_CHANGE_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_RESET,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_RESET,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_VERIFY_CODE_FAIL,
  USER_PASSWORD_RESET_VERIFY_CODE_REQUEST,
  USER_PASSWORD_RESET_VERIFY_CODE_RESET,
  USER_PASSWORD_RESET_VERIFY_CODE_SUCCESS,
  USER_PASSWORD_RESET_VERIFY_EMAIL_FAIL,
  USER_PASSWORD_RESET_VERIFY_EMAIL_REQUEST,
  USER_PASSWORD_RESET_VERIFY_EMAIL_RESET,
  USER_PASSWORD_RESET_VERIFY_EMAIL_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_RESET,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCCESS,
} from '../constants/userConstants';

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGIN_RESET:
      return {};
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const userVerifyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_EMAIL_VERIFY_REQUEST:
      return { loading: true };
    case USER_EMAIL_VERIFY_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
        token: action.payload.token,
      };
    case USER_EMAIL_VERIFY_FAIL:
      return { loading: false, error: action.payload };
    case USER_EMAIL_VERIFY_RESET:
      return {};
    default:
      return state;
  }
};

export const userSendResetCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_VERIFY_EMAIL_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_VERIFY_EMAIL_SUCCESS:
      return { loading: false, token: action.payload.token };
    case USER_PASSWORD_RESET_VERIFY_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case USER_PASSWORD_RESET_VERIFY_EMAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordResetVerifyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_VERIFY_EMAIL_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_VERIFY_EMAIL_SUCCESS:
      return { loading: false, token: action.payload.token };
    case USER_PASSWORD_RESET_VERIFY_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case USER_PASSWORD_RESET_VERIFY_EMAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordResetVerifyCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_VERIFY_CODE_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_VERIFY_CODE_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_PASSWORD_RESET_VERIFY_CODE_FAIL:
      return { loading: false, error: action.payload };
    case USER_PASSWORD_RESET_VERIFY_CODE_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordResetReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_PASSWORD_RESET_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_PASSWORD_RESET_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_CHANGE_REQUEST:
      return { loading: true };
    case USER_PASSWORD_CHANGE_SUCCESS:
      return { loading: false, message: action.payload, success: true };
    case USER_PASSWORD_CHANGE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_PASSWORD_CHANGE_RESET:
      return {};
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        total: action.payload.total,
      };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return { users: [] };
    case USER_LIST_REMOVE_ITEM:
      return {
        ...state,
        users: state.users.filter((user) => user.user_id !== action.payload),
      };

    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { loading: true };
    case USER_CREATE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return { user: {} };
    default:
      return state;
  }
};
