import {
  Button,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCourse, listCourses } from '../../actions/courseActions';
import {
  listEvaluationCourses,
  listEvaluationCoursesTeacher,
} from '../../actions/evaluationActions';
import { listBatches } from '../../actions/batchActions';
import { Link } from 'react-router-dom';
import StyledTableCell from '../../components/StyledTableCell';
import Message from '../../components/Message';
import { listSemesters } from '../../actions/semesterActions';

const EvaluationTeacherScreen = ({ history, match, theme }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    sem_id: '',
    program_id: '',
    course_id: '',
    batch_id: '',
    teacher_id: '',
    sortBy: 'created_at',
    sortingOrder: 'DESC',
  });

  const evaluationCourseListTeacher = useSelector(
    (state) => state.evaluationCourseListTeacher
  );
  const { loading, error, evaluationCourses } = evaluationCourseListTeacher;

  const batchList = useSelector((state) => state.batchList);
  const { loading: loadingBatches, batches, error: errorBatches } = batchList;

  const semesterList = useSelector((state) => state.semesterList);
  const {
    loading: loadingSemester,
    semesters,
    error: errorSemester,
  } = semesterList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    // Fetch the courses on component mount
    if (!evaluationCourses) {
      dispatch(listEvaluationCoursesTeacher(values));
    }

    if (!semesters) {
      dispatch(listSemesters());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, batches]);

  // Fetch the courses on component filter value update
  useEffect(() => {
    dispatch(listEvaluationCoursesTeacher(values));
  }, [dispatch, values]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleProgramChange = (event) => {
    // Fetch the programs on department change
    dispatch(listBatches(event.target.value));
    setValues({ ...values, program_id: event.target.value });
  };

  const handleDelete = (course_id) => {
    if (window.confirm('Are you sure you want to delete this course?')) {
      dispatch(deleteCourse(course_id));
    }
  };

  return (
    <Container>
      <Grid container spacing={1} mb={2}>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            onClick={() => history.push('/courses/add')}
            size="small"
            // style={{ width: '100%', height: '100%' }}
            disableElevation
          >
            <AddIcon /> &nbsp; Add Course
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            value={values.sem_id}
            label="Semester"
            onChange={handleChange('sem_id')}
            style={{ width: '100%' }}
            disabled={loadingSemester}
            size="small"
          >
            <MenuItem value="">All</MenuItem>
            {semesters?.map((semester) => (
              <MenuItem value={semester.sem_id} key={semester.sem_id}>
                {semester.sem_name}
              </MenuItem>
            ))}
          </TextField>
          {loadingSemester && <LinearProgress />}
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortBy}
            label="Sort By"
            onChange={handleChange('sortBy')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="created_at">Creation Time</MenuItem>
            <MenuItem value="updated_at">Update Time</MenuItem>
            <MenuItem value="course_name">Course Name</MenuItem>
            <MenuItem value="course_code">Course Code</MenuItem>
            <MenuItem value="course_credit">Credit</MenuItem>
            <MenuItem value="course_type">Course Type</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortingOrder}
            label="Order"
            onChange={handleChange('sortingOrder')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="ASC">Ascending</MenuItem>
            <MenuItem value="DESC">Descending</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="course list table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>SL</StyledTableCell>
              <StyledTableCell>Course Name</StyledTableCell>

              <StyledTableCell align="left">Code</StyledTableCell>
              <StyledTableCell align="left">Semester</StyledTableCell>

              <StyledTableCell align="center">Evaluation</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {evaluationCourses?.map((course, index) => (
              <TableRow
                key={course.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {course.course_name}
                </TableCell>

                <TableCell align="left">{course.course_code}</TableCell>
                <TableCell align="left">{course.sem_name}</TableCell>

                <TableCell align="center">
                  <IconButton
                    size="small"
                    disabled={!course.published}
                    color="secondary"
                  >
                    <Link to={`/evaluation/report?report_id=${course.id}`}>
                      <ArrowForwardIcon
                        color={course.published ? 'primary' : 'disabled'}
                        fontSize="small"
                      />
                    </Link>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
      </TableContainer>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default EvaluationTeacherScreen;
