import {
  Button,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NDUB_LOGO from '../../img/NDUB_Logo.png';

import Message from '../../components/Message';
import { useEffect } from 'react';
import { emailVerify } from '../../actions/userActions';
import { USER_PASSWORD_RESET_VERIFY_EMAIL_RESET } from '../../constants/userConstants';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PasswordResetEmailVerify = ({ history }) => {
  const dispatch = useDispatch();

  let query = useQuery();

  console.log(query.get('token'));

  const [token, setToken] = useState('');
  const [verificationCode, setVerificationCode] = useState(null);

  const userVerifyEmail = useSelector((state) => state.userVerifyEmail);
  const { loading: loadingVerify, token: tokenVerify } = userVerifyEmail;
  // const { token: tokenVerify } = userInfo;

  useEffect(() => {
    if (!query.get('token')) {
      history.push('/login');
    } else {
      setToken(query.get('token'));
      if (query.get('code')) {
        setVerificationCode(query.get('code'));
        let passwordReset = true;
        dispatch(
          emailVerify(query.get('token'), query.get('code'), passwordReset)
        );
      }
    }
  }, []);

  useEffect(() => {
    if (tokenVerify) {
      history.push(`/reset/confirm?token=${tokenVerify}`);
    }
  }, [history, tokenVerify]);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const onSubmit = (e) => {
    e.preventDefault();
    let passwordReset = true;
    dispatch(emailVerify(token, verificationCode, passwordReset));
  };

  return (
    <Container>
      <Grid
        container
        className="login-container"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          sm={6}
          className="login-art-container"
          direction={'column'}
        >
          <img src={NDUB_LOGO} alt="NDUB Logo" className="login-art" />
          <h2 className="primary-header">Verify your email address</h2>
        </Grid>
        <Grid item xs={12} sm={6} className="grid-center">
          <Card variant="outlined" className="login-form-container">
            <h2 className="primary-header mb-20">Verify</h2>
            <form onSubmit={(e) => onSubmit(e)}>
              <TextField
                variant="outlined"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                label="Verification Code"
                style={{ width: '100%', marginBottom: '20px' }}
              />

              {/* <i className="fas fa-user" style={{ color: 'white' }} /> */}
              <Button
                variant="contained"
                disableElevation
                size="large"
                style={{ width: '100%', marginBottom: '20px' }}
                type="submit"
              >
                Verify
                {loadingVerify && (
                  <>
                    &nbsp;
                    <CircularProgress color="white" size={20} thickness={5} />
                  </>
                )}
              </Button>
            </form>
            <Typography variant="p">
              Already have an account?{' '}
              <Link to="/login" style={{ textDecoration: 'underline' }}>
                Login
              </Link>{' '}
              | Did not receive code?{' '}
              <Link
                to="/reset"
                onClick={() =>
                  dispatch({ type: USER_PASSWORD_RESET_VERIFY_EMAIL_RESET })
                }
                style={{ textDecoration: 'underline' }}
              >
                Start over
              </Link>
            </Typography>
          </Card>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default PasswordResetEmailVerify;
