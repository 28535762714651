import {
  FACULTY_LIST_REQUEST,
  FACULTY_LIST_SUCCESS,
  FACULTY_LIST_FAIL,
  FACULTY_LIST_RESET,
  FACULTY_LIST_REMOVE_ITEM,
  FACULTY_DETAILS_REQUEST,
  FACULTY_DETAILS_SUCCESS,
  FACULTY_DETAILS_FAIL,
  FACULTY_DETAILS_RESET,
  FACULTY_ADD_REQUEST,
  FACULTY_ADD_SUCCESS,
  FACULTY_ADD_FAIL,
  FACULTY_ADD_RESET,
  FACULTY_EDIT_REQUEST,
  FACULTY_EDIT_SUCCESS,
  FACULTY_EDIT_FAIL,
  FACULTY_EDIT_RESET,
  FACULTY_DELETE_REQUEST,
  FACULTY_DELETE_SUCCESS,
  FACULTY_DELETE_FAIL,
  FACULTY_DELETE_RESET,
} from '../constants/facultyConstants';

export const facultyListReducer = (state = {}, action) => {
  switch (action.type) {
    case FACULTY_LIST_REQUEST:
      return { loading: true };
    case FACULTY_LIST_SUCCESS:
      return {
        loading: false,
        faculties: action.payload,
      };
    case FACULTY_LIST_REMOVE_ITEM:
      return {
        ...state,
        faculties: state.faculties.filter(
          (faculty) => faculty.fac_id !== action.payload
        ),
      };
    case FACULTY_LIST_FAIL:
      return { loading: false, error: action.payload };
    case FACULTY_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const facultyDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case FACULTY_DETAILS_REQUEST:
      return { loading: true };
    case FACULTY_DETAILS_SUCCESS:
      return {
        loading: false,
        faculty: action.payload,
      };
    case FACULTY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case FACULTY_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const facultyAddReducer = (state = {}, action) => {
  switch (action.type) {
    case FACULTY_ADD_REQUEST:
      return { loading: true };
    case FACULTY_ADD_SUCCESS:
      return {
        loading: false,
        faculty: action.payload,
        success: true,
      };
    case FACULTY_ADD_FAIL:
      return { loading: false, error: action.payload, success: false };
    case FACULTY_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const facultyEditReducer = (state = {}, action) => {
  switch (action.type) {
    case FACULTY_EDIT_REQUEST:
      return { loading: true };
    case FACULTY_EDIT_SUCCESS:
      return {
        loading: false,
        faculty: action.payload,
        success: true,
      };
    case FACULTY_EDIT_FAIL:
      return { loading: false, error: action.payload, success: false };
    case FACULTY_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const facultyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FACULTY_DELETE_REQUEST:
      return { loading: true };
    case FACULTY_DELETE_SUCCESS:
      return {
        loading: false,
        faculty: action.payload,
        success: true,
      };
    case FACULTY_DELETE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case FACULTY_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
