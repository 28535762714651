import {
  Button,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Pagination,
  FormControl,
  Select,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCourse, listCourses } from '../../actions/courseActions';
import { listPrograms } from '../../actions/programActions';
import { listDepartments } from '../../actions/departmentActions';
import { Link } from 'react-router-dom';
import StyledTableCell from '../../components/StyledTableCell';
import Message from '../../components/Message';
import {
  COURSE_ADD_RESET,
  COURSE_EDIT_RESET,
} from '../../constants/courseConstants';
import debounce from 'lodash.debounce';

const CourseListScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    course_name: '',
    course_code: '',
    course_type: '',
    course_credit: '',
    program_id: '',
    sortBy: 'created_at',
    sortingOrder: 'DESC',
  });

  const [department, setDepartment] = useState();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pagesFromAPI, setPagesFromAPI] = useState(null);

  const courseList = useSelector((state) => state.courseList);
  const { loading, error, courses, page, pages, total } = courseList;

  const batchList = useSelector((state) => state.batchList);
  const { loading: loadingBatches, batches, error: errorBatches } = batchList;

  const programList = useSelector((state) => state.programList);
  const { loading: loadingPrograms, programs } = programList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const courseAdd = useSelector((state) => state.courseAdd);
  const { success: successAdd } = courseAdd;

  const courseEdit = useSelector((state) => state.courseEdit);
  const { success: successEdit } = courseEdit;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    // Reset the course add state in redux
    if (successAdd) {
      dispatch({ type: COURSE_ADD_RESET });
    }

    // Reset the course edit state in redux
    if (successEdit) {
      dispatch({ type: COURSE_EDIT_RESET });
    }

    // Fetch the courses on component mount
    if (!courses) {
      dispatch(
        listCourses(
          values.program_id,
          values.course_code,
          values.course_name,
          values.course_type,
          values.course_credit,
          values.sortBy,
          values.sortingOrder,
          pageNumber,
          pageSize
        )
      );
    }

    // Fetch the departments and programs on component mount
    if (!departments) {
      dispatch(listDepartments());
    }
    if (!programs) {
      dispatch(listPrograms());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch the courses on component filer value update
  useEffect(() => {
    dispatch(
      listCourses(
        values.program_id,
        values.course_code,
        values.course_name,
        values.course_type,
        values.course_credit,
        values.sortBy,
        values.sortingOrder,
        pageNumber,
        pageSize
      )
    );
  }, [dispatch, pageNumber, pageSize, pagesFromAPI]);

  const loadData = (values) => {
    dispatch(
      listCourses(
        values.program_id,
        values.course_code,
        values.course_name,
        values.course_type,
        values.course_credit,
        values.sortBy,
        values.sortingOrder,
        pageNumber,
        pageSize
      )
    );
  };

  const delayedQuery = useRef(
    debounce((values) => loadData(values), 1000)
  ).current;

  const handleChange = (prop) => (event) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setValues({ ...values, [prop]: event.target.value });
    delayedQuery({ ...values, [prop]: event.target.value });
  };

  const handleDepartmentChange = (event) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setDepartment(event.target.value);
    console.log(event.target.value);
    // Fetch the programs on department change
    dispatch(listPrograms(event.target.value));
  };

  const handleDelete = (course_id) => {
    if (window.confirm('Are you sure you want to delete this course?')) {
      dispatch(deleteCourse(course_id));
    }
  };

  const handlePageChange = (event, value) => {
    setPagesFromAPI(pages);
    setPageNumber(value);
  };

  const handlePageSizeChange = (event) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setPageSize(event.target.value);
  };

  return (
    <Container>
      <Grid container spacing={1} mb={2}>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            onClick={() => history.push('/courses/add')}
            size="small"
            // style={{ width: '100%', height: '100%' }}
            disableElevation
          >
            <AddIcon /> &nbsp; Add Course
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="text"
            value={values.course_name}
            onChange={handleChange('course_name')}
            label="Course Name"
            style={{ width: '100%' }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="text"
            value={values.course_code}
            onChange={handleChange('course_code')}
            label="Course Code"
            style={{ width: '100%' }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="text"
            value={values.course_credit}
            onChange={handleChange('course_credit')}
            label="Credit"
            style={{ width: '100%' }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="text"
            value={values.course_type}
            onChange={handleChange('course_type')}
            label="Course Type"
            style={{ width: '100%' }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            value={department}
            label="Department"
            onChange={(event) => handleDepartmentChange(event)}
            style={{ width: '100%' }}
            disabled={loadingDepartments}
            size="small"
          >
            <MenuItem value="">All</MenuItem>
            {departments?.map((department) => (
              <MenuItem value={department.dept_id} key={department.dept_id}>
                {department.dept_name}
              </MenuItem>
            ))}
          </TextField>
          {loadingDepartments && <LinearProgress />}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            value={values.program_id}
            label="Program"
            onChange={handleChange('program_id')}
            style={{ width: '100%' }}
            disabled={loadingPrograms}
            size="small"
          >
            <MenuItem value="">All</MenuItem>
            {programs?.map((program) => (
              <MenuItem value={program.program_id} key={program.program_id}>
                {program.program_name}
              </MenuItem>
            ))}
          </TextField>
          {loadingPrograms && <LinearProgress />}
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortBy}
            label="Sort By"
            onChange={handleChange('sortBy')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="created_at">Creation Time</MenuItem>
            <MenuItem value="updated_at">Update Time</MenuItem>
            <MenuItem value="course_name">Course Name</MenuItem>
            <MenuItem value="course_code">Course Code</MenuItem>
            <MenuItem value="course_credit">Credit</MenuItem>
            <MenuItem value="course_type">Course Type</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortingOrder}
            label="Order"
            onChange={handleChange('sortingOrder')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="ASC">Ascending</MenuItem>
            <MenuItem value="DESC">Descending</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Pagination
              disabled={loading}
              count={pages ? pages : pagesFromAPI}
              page={pageNumber}
              boundaryCount={2}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
              color="primary"
            />
            <p style={{ marginLeft: '20px' }}>Page Size: &nbsp;</p>
            <FormControl size="small">
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                size="small"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </FormControl>
            {total ? (
              <small style={{ marginLeft: '20px' }}>
                {total} records found
              </small>
            ) : (
              <small style={{ marginLeft: '20px' }}>No records found</small>
            )}
          </div>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="course list table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>SL</StyledTableCell>
              <StyledTableCell>Course Name</StyledTableCell>
              <StyledTableCell align="left">Code</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Credit</StyledTableCell>
              <StyledTableCell align="left">Program</StyledTableCell>
              <StyledTableCell align="center">Delete</StyledTableCell>
              <StyledTableCell align="center">View/Edit</StyledTableCell>
              {/* <TableCell align="left">URL</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {courses?.map((course, index) => (
              <TableRow
                key={course.course_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {course.course_name}
                </TableCell>
                <TableCell align="left">{course.course_code}</TableCell>
                <TableCell align="left">{course.course_type}</TableCell>
                <TableCell align="left">{course.course_credit}</TableCell>
                <TableCell align="left">{course.program_code}</TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(course.course_id)}
                  >
                    <DeleteIcon color="error" fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Link to={`/courses/edit/${course.course_id}`}>
                    <IconButton size="small">
                      <ArrowForwardIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
      </TableContainer>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default CourseListScreen;
