import { Autocomplete, LinearProgress, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { listTeachers } from '../../actions/teacherActions';

const TeacherSearchableDropdown = ({ teacher, setTeacher, id }) => {
  const dispatch = useDispatch();
  const teacherList = useSelector((state) => state.teacherList);
  const { loading: loadingTeachers, teachers } = teacherList;

  // Set default props for teachers dropdown
  const defaultTeacherProps = {
    options: teachers ? teachers : [],
    getOptionLabel: (teacher) => teacher.teacher_name,
  };

  useEffect(() => {
    if (teachers && teachers.length <= 50) {
      dispatch(listTeachers());
    } else if (!teachers) {
      dispatch(listTeachers());
    }
  }, []);

  return (
    <>
      <Autocomplete
        {...defaultTeacherProps}
        value={teacher}
        onChange={(event, newValue) => {
          setTeacher(newValue, id);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Teacher"
            variant="outlined"
            size="small"
          />
        )}
      />
      {loadingTeachers && <LinearProgress />}
    </>
  );
};

export default TeacherSearchableDropdown;
