import axios from 'axios';
import {
  ALLOCATION_ADD_REQUEST,
  ALLOCATION_ADD_SUCCESS,
  ALLOCATION_ADD_FAIL,
  ALLOCATION_ADD_BULK_REQUEST,
  ALLOCATION_ADD_BULK_SUCCESS,
  ALLOCATION_ADD_BULK_FAIL,
  ALLOCATION_LIST_SUCCESS,
  ALLOCATION_LIST_REQUEST,
  ALLOCATION_LIST_FAIL,
  ALLOCATION_DELETE_REQUEST,
  ALLOCATION_DELETE_SUCCESS,
  ALLOCATION_LIST_REMOVE_ITEM,
  ALLOCATION_DELETE_FAIL,
  ALLOCATION_EDIT_REQUEST,
  ALLOCATION_EDIT_SUCCESS,
  ALLOCATION_EDIT_FAIL,
  ALLOCATION_DETAILS_REQUEST,
  ALLOCATION_DETAILS_SUCCESS,
  ALLOCATION_DETAILS_FAIL,
} from '../constants/allocationConstants';

import { setAlert } from './alertActions';

// Get all course allocations
// @access admin
export const listCourseAllocations =
  (
    sem_id = '',
    course_id = '',
    batch_id = '',
    teacher_id = '',
    student_serial_id = '',
    sortBy = '',
    sortingOrder = '',
    pageNumber = 1,
    pageSize = 50,
    repeat_retake = false
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: ALLOCATION_LIST_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `/api/student-course?sem_id=${sem_id}&course_id=${course_id}&batch_id=${batch_id}&teacher_id=${teacher_id}&student_serial_id=${student_serial_id}&repeat_retake=${repeat_retake}&sortBy=${sortBy}&sortingOrder=${sortingOrder}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        config
      );

      dispatch({
        type: ALLOCATION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: ALLOCATION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get course allocations details by id
// @access admin
export const courseAllocationsDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: ALLOCATION_DETAILS_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/student-course/${id}`, config);

    dispatch({
      type: ALLOCATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: ALLOCATION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Add new single allocation
export const addAllocation = (values) => async (dispatch) => {
  try {
    dispatch({ type: ALLOCATION_ADD_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(`/api/student-course`, values, config);

    dispatch({
      type: ALLOCATION_ADD_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Allocation added', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: ALLOCATION_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Add new bulk allocation
export const addBulkAllocation = (values) => async (dispatch) => {
  try {
    dispatch({ type: ALLOCATION_ADD_BULK_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(
      `/api/student-course/bulk`,
      values,
      config
    );

    dispatch({
      type: ALLOCATION_ADD_BULK_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Allocations added', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: ALLOCATION_ADD_BULK_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Delete an allocation
export const deleteAllocation = (student_course_id) => async (dispatch) => {
  try {
    dispatch({ type: ALLOCATION_DELETE_REQUEST });

    const { data } = await axios.delete(
      `/api/student-course/${student_course_id}`
    );

    dispatch({
      type: ALLOCATION_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: ALLOCATION_LIST_REMOVE_ITEM,
      payload: student_course_id,
    });
    dispatch(setAlert('Allocation deleted', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: ALLOCATION_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Edit single allocation details
export const editAllocation =
  (student_course_id, values) => async (dispatch, getState) => {
    try {
      dispatch({ type: ALLOCATION_EDIT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/student-course/${student_course_id}`,
        values,
        config
      );

      dispatch({
        type: ALLOCATION_EDIT_SUCCESS,
        payload: data,
      });
      dispatch(setAlert('Allocation edited', 'success'));
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: ALLOCATION_EDIT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
