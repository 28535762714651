import React from 'react';
import { Container, Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const HelpScreen = () => {
  return (
    <Container>
      <h2
        className="primary-header"
        style={{ marginTop: '20px', marginBottom: '10px' }}
      >
        Help
      </h2>
      <Divider />
      <br />
      <Typography>
        If you face any difficulties with the portal, please contact one of the
        following email addresses: <br />
        <address>joydhon@ndub.edu.bd</address>
        <address>ishan@ndub.edu.bd</address>
        <br />
        Please include the following information:
        <ul>
          <li>
            Your name, ndub email, batch, department, student ID, phone number
          </li>
          <li>Detailed explanation of the problem you are facing</li>
          <li>
            When and where (in which point or page) you are facing the issue
          </li>
          <li>Screenshots of the issue</li>
        </ul>
      </Typography>
      Need help?{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://ndub.edu.bd/wp-content/uploads/2022/04/evaluation_tutorial_new.pdf"
      >
        PDF Tutorial
      </a>
      <h2
        className="primary-header"
        style={{ marginTop: '20px', marginBottom: '10px' }}
      >
        Frequently asked questions
      </h2>
      <Divider /> <br />
      <div>
        <Accordion variant="outlined">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>How do I register for an account?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color: 'text.secondary' }}>
              <ol>
                <li>
                  Make sure you have access to and can login to your NDUB Email
                </li>
                <li>
                  Visit{' '}
                  <a
                    href="https://portal.ndub.edu.bd/register"
                    style={{
                      textDecoration: 'underline',
                      fontWeight: '600',
                      color: 'blue',
                    }}
                  >
                    https://portal.ndub.edu.bd/register
                  </a>
                </li>
                <li>
                  Insert your NDUB Email and your desired password. Passwords
                  can be same as your NDUB Email, or different, that is your
                  choice.
                </li>
                <li>
                  Click on register, a verification code will be sent to your
                  NDUB Email
                </li>
                <li>
                  Open your NDUB Email and copy the code then paste it in the
                  Portal registration page, then click submit. Alternatively you
                  can click the link that is provided in the email as well.
                </li>
                <li>
                  If everything goes well, you will be logged in directly to the
                  portal. You can now start using it.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion variant="outlined">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Are my NDUB Student Email and Student Portal passwords same?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              No, they are not. The accounts are not already created at the
              student portal, that is why you need to{' '}
              <Link
                to="/register"
                style={{
                  textDecoration: 'underline',
                  fontWeight: '600',
                  color: 'blue',
                }}
              >
                register
              </Link>{' '}
              an account in the NDUB student portal upon your first visit. We
              will send you a verification code to your NDUB student email to
              verify your identity. While registering you can put in the same
              password as your NDUB student email, that choice is yours.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion variant="outlined">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Why do I have to register an new account if I already have NDUB
              student email?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color: 'text.secondary' }}>
              NDUB Student email service and NDUB Student Portal are completely
              different and independent services. We use your student email to
              verify and confirm your identity. You need to use your student
              email to register/sign up to the student portal.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion variant="outlined">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              My email is not registered in the NDUB Database, what should I do?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color: 'text.secondary' }}>
              If you face this issue please contact the NDUB IT Office (Room No:
              110, Ground Floor), find Mr. Joydhon Chakma and tell him about
              your issue. He will fix it as soon as possible with your help.{' '}
              <br />
              Alternatively, you can email him at{' '}
              <code>joydhon@ndub.edu.bd</code> explaining your issue. He will
              help you out.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <br />
      <Link to="/" style={{ textDecoration: 'underline', fontWeight: '600' }}>
        Go back
      </Link>
    </Container>
  );
};

export default HelpScreen;
