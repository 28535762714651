import React, { useEffect, useRef, useState } from 'react';
import { deleteUser, listUsers } from '../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import StyledTableCell from '../../components/StyledTableCell';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { debounce } from 'lodash';
import { format } from 'date-fns';

const UserListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users, page, pages, total } = userList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const [values, setValues] = useState({
    name: '',
    user_email: '',
    is_student: '',
    is_faculty: '',
    is_admin: '',
    is_superadmin: '',
    sortBy: 'created_at',
    sortingOrder: 'DESC',
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pagesFromAPI, setPagesFromAPI] = useState(null);

  useEffect(() => {
    dispatch(
      listUsers(
        values.name,
        values.user_email,
        values.is_student,
        values.is_faculty,
        values.is_admin,
        values.is_superadmin,
        values.sortBy,
        values.sortingOrder,
        pageNumber,
        pageSize
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      listUsers(
        values.name,
        values.user_email,
        values.is_student,
        values.is_faculty,
        values.is_admin,
        values.is_superadmin,
        values.sortBy,
        values.sortingOrder,
        pageNumber,
        pageSize
      )
    );
  }, [
    dispatch,
    values.is_student,
    values.is_admin,
    values.is_faculty,
    values.is_superadmin,
    pageNumber,
    pageSize,
  ]);

  const loadData = (values) => {
    // load users from server
    dispatch(
      listUsers(
        values.name,
        values.user_email,
        values.is_student,
        values.is_faculty,
        values.is_admin,
        values.is_superadmin,
        values.sortBy,
        values.sortingOrder,
        pageNumber,
        pageSize
      )
    );
  };

  const delayedQuery = useRef(
    debounce((values) => loadData(values), 1000)
  ).current;

  const handleChange = (prop) => (event) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setValues({ ...values, [prop]: event.target.value });
    delayedQuery({ ...values, [prop]: event.target.value });
  };

  const handlePageChange = (event, value) => {
    setPagesFromAPI(pages);
    setPageNumber(value);
  };

  const handlePageSizeChange = (event) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setPageSize(event.target.value);
  };

  const handleUserTypeChange = (prop) => (event) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleDelete = (id) => {
    window.confirm('Are you sure you want to delete this user?') &&
      dispatch(deleteUser(id));
  };

  return (
    <Container>
      <Grid container spacing={1} mb={2}>
        <Meta
          title={'Users'}
          description={'Users of Notre Dame University Bangladesh'}
          keywords={'ndub users, add ndub users, ndub user'}
        />
        {/* <h1 className="primary-header">Department List Screen</h1> <br /> */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => history.push('/users/add')}
            disableElevation
          >
            <AddIcon /> &nbsp; Add User
          </Button>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            size="small"
            type="text"
            value={values.name}
            onChange={handleChange('name')}
            label="Name"
            style={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            size="small"
            type="text"
            value={values.user_email}
            onChange={handleChange('user_email')}
            label="Email"
            style={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortBy}
            label="Sort By"
            onChange={handleChange('sortBy')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="created_at">Creation Time</MenuItem>
            <MenuItem value="updated_at">Update Time</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="user_email">Email</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortingOrder}
            label="Order"
            onChange={handleChange('sortingOrder')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="ASC">Ascending</MenuItem>
            <MenuItem value="DESC">Descending</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12}>
          Role: &nbsp;
          <FormControlLabel
            control={<Checkbox checked={values.is_student} />}
            onChange={handleUserTypeChange('is_student')}
            label="Student"
          />
          <FormControlLabel
            control={<Checkbox checked={values.is_faculty} />}
            onChange={handleUserTypeChange('is_faculty')}
            label="Faculty"
          />
          <FormControlLabel
            control={<Checkbox checked={values.is_admin} />}
            onChange={handleUserTypeChange('is_admin')}
            label="Admin"
          />
          <FormControlLabel
            control={<Checkbox checked={values.is_superadmin} />}
            onChange={handleUserTypeChange('is_superadmin')}
            label="Super Admin"
          />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Pagination
              disabled={loading}
              count={pages ? pages : pagesFromAPI}
              page={pageNumber}
              boundaryCount={2}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
              color="primary"
            />
            <p style={{ marginLeft: '20px' }}>Page Size: &nbsp;</p>
            <FormControl size="small">
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                size="small"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </FormControl>
            {total ? (
              <small style={{ marginLeft: '20px' }}>
                {total} records found
              </small>
            ) : (
              <small style={{ marginLeft: '20px' }}>No records found</small>
            )}
          </div>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="users table">
          <TableHead>
            <TableRow>
              <StyledTableCell>SL</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Student</StyledTableCell>
              <StyledTableCell align="left">Faculty</StyledTableCell>
              <StyledTableCell align="left">Admin</StyledTableCell>
              <StyledTableCell align="left">Super Admin</StyledTableCell>
              <StyledTableCell align="left">Created</StyledTableCell>
              <StyledTableCell align="left">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user, index) => (
              <TableRow
                key={user.user_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>

                <TableCell align="left">{user.name}</TableCell>
                <TableCell align="left">{user.user_email}</TableCell>
                <TableCell align="left">
                  {user.is_student ? 'Yes' : 'No'}
                </TableCell>
                <TableCell align="left">
                  {user.is_faculty ? 'Yes' : 'No'}
                </TableCell>
                <TableCell align="left">
                  {user.is_admin ? 'Yes' : 'No'}
                </TableCell>
                <TableCell align="left">
                  {user.is_superadmin ? 'Yes' : 'No'}
                </TableCell>
                <TableCell align="left">
                  {format(new Date(user.created_at), 'PPp')}
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(user.user_id)}
                  >
                    <DeleteIcon color="error" fontSize="small" />
                  </IconButton>
                </TableCell>
                {/* <TableCell align="center">
                  <Link to={`/users/edit/${user.stu_serial_id}`}>
                    <IconButton aria-label="delete" size="small">
                      <ArrowForwardIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
      </TableContainer>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default UserListScreen;
