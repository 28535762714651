import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import { listFaculties } from '../../actions/facultyActions';
import { addDepartment } from '../../actions/departmentActions';

const DepartmentAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    dept_name: '',
    dept_code: '',
    dept_url: '',
    fac_id: '',
  });

  const departmentAdd = useSelector((state) => state.departmentAdd);
  const { loading, success, error, department } = departmentAdd;

  const facultyList = useSelector((state) => state.facultyList);
  const {
    loading: loadingFaculties,
    error: errorFaculties,
    faculties,
  } = facultyList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push('/departments');
      }, 2000);
    } else {
      if (!faculties) {
        dispatch(listFaculties());
      }
    }
  }, [history, dispatch, success, faculties]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleFacultyChange = (event) => {
    setValues({ ...values, fac_id: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(addDepartment(values));
  };

  return (
    <Grid container spacing={2}>
      <Meta
        title={'Add Department'}
        description={
          'Add new department to the database of Notre Dame University Bangladesh'
        }
        keywords={'add new department, department'}
      />
      {/* <form onSubmit={(e) => onSubmit(e)} style={{ width: '100%' }}> */}
      <Grid item xs={12}>
        <Typography variant="h4">Add new department</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="outlined"
          type="text"
          value={values.dept_name}
          onChange={handleChange('dept_name')}
          label="Department Name"
          style={{ width: '100%' }}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          type="text"
          value={values.dept_code}
          onChange={handleChange('dept_code')}
          label="Department Code"
          style={{ width: '100%' }}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          type="text"
          value={values.dept_url}
          onChange={handleChange('dept_url')}
          label="Department URL"
          style={{ width: '100%' }}
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          select
          value={values.fac_id}
          label="Faculty"
          onChange={(event) => handleFacultyChange(event)}
          style={{ width: '100%' }}
          disabled={loadingFaculties}
          size="small"
        >
          {faculties?.map((faculty) => (
            <MenuItem value={faculty.fac_id} key={faculty.fac_id}>
              {faculty.fac_name}
            </MenuItem>
          ))}
        </TextField>
        {loadingFaculties && <LinearProgress />}
      </Grid>

      <Grid item xs={12}>
        <Link to={'/departments'}>
          <Button
            variant="outlined"
            disableElevation
            // size="large"
            type="submit"
          >
            Back
          </Button>
        </Link>
        <Button
          variant="contained"
          disableElevation
          // size="large"
          style={{ marginLeft: '10px' }}
          onClick={(e) => onSubmit(e)}
        >
          Add{' '}
          {loading && (
            <>
              &nbsp;
              <CircularProgress color="white" size={20} thickness={5} />
            </>
          )}
        </Button>
      </Grid>
      {/* </form> */}
      {msg?.length > 0 && <Message />}
    </Grid>
  );
};

export default DepartmentAddScreen;
