import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listDepartments } from '../../actions/departmentActions';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import { addTeacher } from '../../actions/teacherActions';

const TeacherAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    teacher_name: '',
    teacher_designation: '',
    teacher_email: '',
    dept_id: '',
  });

  const teacherAdd = useSelector((state) => state.teacherAdd);
  const { loading, success, error, teacher } = teacherAdd;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push('/teachers');
      }, 2000);
    } else {
      dispatch(listDepartments());
    }
  }, [dispatch, history, success]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDepartmentChange = (event) => {
    setValues({ ...values, dept_id: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(addTeacher(values));
  };

  return (
    <Container>
      <Meta
        title={'Add Teacher'}
        description={
          'Add new teacher to the database of Notre Dame University Bangladesh'
        }
        keywords={'add new teacher, register teacher'}
      />
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Add new teacher</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              type="text"
              value={values.teacher_name}
              onChange={handleChange('teacher_name')}
              label="Teacher Name"
              style={{ width: '100%' }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              type="text"
              value={values.teacher_designation}
              onChange={handleChange('teacher_designation')}
              label="Designation"
              style={{ width: '100%' }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              type="text"
              value={values.teacher_email}
              onChange={handleChange('teacher_email')}
              label="Email"
              style={{ width: '100%' }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              value={values.dept_id}
              label="Department"
              onChange={(event) => handleDepartmentChange(event)}
              style={{ width: '100%' }}
              disabled={loadingDepartments}
              size="small"
            >
              {departments?.map((department) => (
                <MenuItem value={department.dept_id} key={department.dept_id}>
                  {department.dept_name}
                </MenuItem>
              ))}
            </TextField>
            {loadingDepartments && <LinearProgress />}
          </Grid>

          <Grid item xs={12}>
            <Link to={'/teachers'}>
              <Button variant="outlined" disableElevation>
                Back
              </Button>
            </Link>
            <Button
              variant="contained"
              disableElevation
              type="submit"
              style={{ marginLeft: '10px' }}
            >
              Add{' '}
              {loading && (
                <>
                  &nbsp;
                  <CircularProgress color="white" size={20} thickness={5} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default TeacherAddScreen;
