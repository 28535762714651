import { Autocomplete, LinearProgress, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const SemesterSearchableDropdown = ({ semester, setSemester }) => {
  const semesterList = useSelector((state) => state.semesterList);
  const { loading: loadingSemester, semesters } = semesterList;

  // Set default props for semesters dropdown
  const defaultSemesterProps = {
    options: semesters ? semesters : [],
    getOptionLabel: (semester) => `${semester.sem_name} ${semester.sem_year}`,
  };

  return (
    <>
      <Autocomplete
        {...defaultSemesterProps}
        value={semester}
        onChange={(event, newValue) => {
          setSemester(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Semester"
            variant="outlined"
            size="small"
          />
        )}
      />
      {loadingSemester && <LinearProgress />}
    </>
  );
};

export default SemesterSearchableDropdown;
