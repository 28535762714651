import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/userActions';
import LogoutIcon from '@mui/icons-material/Logout';
import { withRouter } from 'react-router';

const drawerWidth = 240;

const Header = ({ history, handleDrawerToggle }) => {
  const dispatch = useDispatch();

  const onLogOut = () => {
    dispatch(logout());
  };

  const pathName = history.location.pathname;

  const headerText = () => {
    if (pathName === '/dashboard') {
      return 'Dashboard';
    } else if (pathName.includes('/students')) {
      return 'Students';
    } else if (pathName.includes('/evaluation')) {
      return 'Evaluation';
    } else if (pathName === '/student/add') {
      return 'Add Student';
    } else if (pathName.includes('/departments')) {
      return 'Departments';
    } else if (pathName.includes('/program')) {
      return 'Program';
    } else if (pathName.includes('/courses')) {
      return 'Courses';
    } else if (pathName.includes('/teachers')) {
      return 'Teachers';
    } else if (pathName.includes('/semesters')) {
      return 'Semesters';
    } else if (pathName.includes('/allocations')) {
      return 'Course Allocations';
    } else if (pathName.includes('/profile')) {
      return 'Profile';
    } else if (pathName.includes('/users')) {
      return 'Users';
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {headerText()}
        </Typography>
        <IconButton onClick={onLogOut} color="white">
          <LogoutIcon variant="stacked" />
        </IconButton>
        {/* <Avatar
            alt="Remy Sharp"
            src="https://ndub.edu.bd/wp-content/uploads/2021/06/Notre-Dame-University-Bangladesh-Logo-Transparent.png"
          /> */}
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Header);
