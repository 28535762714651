import {
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DEPARTMENT_LIST_FAIL,
  DEPARTMENT_LIST_RESET,
  DEPARTMENT_LIST_REMOVE_ITEM,
  DEPARTMENT_ADD_REQUEST,
  DEPARTMENT_ADD_SUCCESS,
  DEPARTMENT_ADD_FAIL,
  DEPARTMENT_ADD_RESET,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DELETE_RESET,
  DEPARTMENT_DELETE_FAIL,
  DEPARTMENT_DETAILS_REQUEST,
  DEPARTMENT_DETAILS_SUCCESS,
  DEPARTMENT_DETAILS_FAIL,
  DEPARTMENT_DETAILS_RESET,
  DEPARTMENT_EDIT_REQUEST,
  DEPARTMENT_EDIT_SUCCESS,
  DEPARTMENT_EDIT_FAIL,
  DEPARTMENT_EDIT_RESET,
} from '../constants/departmentConstants';

export const departmentListReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_LIST_REQUEST:
      return { loading: true };
    case DEPARTMENT_LIST_SUCCESS:
      return {
        loading: false,
        departments: action.payload,
      };
    case DEPARTMENT_LIST_REMOVE_ITEM:
      return {
        ...state,
        departments: state.departments.filter(
          (department) => department.dept_id !== action.payload
        ),
      };
    case DEPARTMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case DEPARTMENT_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const departmentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_DETAILS_REQUEST:
      return { loading: true };
    case DEPARTMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        department: action.payload,
      };
    case DEPARTMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case DEPARTMENT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const departmentEditReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_EDIT_REQUEST:
      return { loading: true };
    case DEPARTMENT_EDIT_SUCCESS:
      return {
        loading: false,
        department: action.payload,
        success: true,
      };
    case DEPARTMENT_EDIT_FAIL:
      return { loading: false, error: action.payload, success: false };
    case DEPARTMENT_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const departmentAddReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_ADD_REQUEST:
      return { loading: true };
    case DEPARTMENT_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        department: action.payload,
      };
    case DEPARTMENT_ADD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case DEPARTMENT_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const departmentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_DELETE_REQUEST:
      return { loading: true };
    case DEPARTMENT_DELETE_SUCCESS:
      return {
        loading: false,
        department: action.payload,
      };
    case DEPARTMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case DEPARTMENT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
