export const PROGRAM_LIST_REQUEST = 'PROGRAM_LIST_REQUEST';
export const PROGRAM_LIST_SUCCESS = 'PROGRAM_LIST_SUCCESS';
export const PROGRAM_LIST_FAIL = 'PROGRAM_LIST_FAIL';
export const PROGRAM_LIST_RESET = 'PROGRAM_LIST_RESET';
export const PROGRAM_LIST_REMOVE_ITEM = 'PROGRAM_LIST_REMOVE_ITEM';

export const PROGRAM_DETAILS_REQUEST = 'PROGRAM_DETAILS_REQUEST';
export const PROGRAM_DETAILS_SUCCESS = 'PROGRAM_DETAILS_SUCCESS';
export const PROGRAM_DETAILS_FAIL = 'PROGRAM_DETAILS_FAIL';
export const PROGRAM_DETAILS_RESET = 'PROGRAM_DETAILS_RESET';

export const PROGRAM_EDIT_REQUEST = 'PROGRAM_EDIT_REQUEST';
export const PROGRAM_EDIT_SUCCESS = 'PROGRAM_EDIT_SUCCESS';
export const PROGRAM_EDIT_FAIL = 'PROGRAM_EDIT_FAIL';
export const PROGRAM_EDIT_RESET = 'PROGRAM_EDIT_RESET';

export const PROGRAM_ADD_REQUEST = 'PROGRAM_ADD_REQUEST';
export const PROGRAM_ADD_SUCCESS = 'PROGRAM_ADD_SUCCESS';
export const PROGRAM_ADD_FAIL = 'PROGRAM_ADD_FAIL';
export const PROGRAM_ADD_RESET = 'PROGRAM_ADD_RESET';

export const PROGRAM_DELETE_REQUEST = 'PROGRAM_DELETE_REQUEST';
export const PROGRAM_DELETE_SUCCESS = 'PROGRAM_DELETE_SUCCESS';
export const PROGRAM_DELETE_FAIL = 'PROGRAM_DELETE_FAIL';
export const PROGRAM_DELETE_RESET = 'PROGRAM_DELETE_RESET';
