import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import { useDispatch, useSelector } from 'react-redux';
import { sendMultiSMS } from '../../actions/smsActions';

export default function SmsMultiPopup({ phones, message, disabled }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [smsBody, setSmsBody] = useState('');

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const smsMultiSend = useSelector((state) => state.smsMultiSend);
  const { loading: loadingSms, data } = smsMultiSend;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSms = (phones) => {
    // window.alert(phones);
    dispatch(sendMultiSMS({ phones: phoneNumbers, message: smsBody }));
  };

  useEffect(() => {
    setPhoneNumbers(phones);
    setSmsBody(message);
  }, []);

  return (
    <>
      {/* <IconButton
        aria-label="mail"
        size="small"
        disabled={disabled}
        onClick={handleClickOpen}
      >
        <SmsIcon color={disabled ? 'disabled' : 'primary'} fontSize="small" />
      </IconButton> */}

      <Button
        size="medium"
        onClick={handleClickOpen}
        disabled={disabled}
        style={{ marginTop: '-6px' }}
      >
        Send SMS
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send SMS</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <TextField
            margin="dense"
            id="name"
            label="Phone Number"
            type="phones"
            size="small"
            fullWidth
            value={phoneNumbers}
            disabled
            onChange={(e) => setPhoneNumbers(e.target.value)}
          />
          <TextField
            id="subject"
            label="Message"
            margin="dense"
            size="small"
            multiline
            rows={10}
            fullWidth
            value={smsBody}
            onChange={(e) => setSmsBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={() => onSms(phones)} disabled={loadingSms}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
