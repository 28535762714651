import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  evaluationCourseCalculate,
  getEvaluationCourseDetails,
  getEvaluationOverallResultDetails,
  getEvaluationPerQuesResult,
  getEvaluationPerQuesWrittenResult,
  mailEvaluationResult,
  pdfEvaluationResult,
  publishEvaluationResult,
} from '../../actions/evaluationActions';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import EvaluationReport from '../../components/pdf-reports/EvaluationReport';
import StyledTableCell from '../../components/StyledTableCell';
import EvaluationTeacherResultScreen from '../../components/EvaluationPerQuesAnswer';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import queryString from 'query-string';
import {
  EVALUATION_RESULT_MAIL_RESET,
  EVALUATION_RESULT_PDF_RESET,
} from '../../constants/evaluationConstants';
import PerQuesCsvDownloader from '../../components/PerQuesCsvDownloader';
import EvaluationAdminStudentResultScreen from './EvaluationAdminStudentResultScreen';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box } from '@mui/system';

const EvaluationAdminTeacherResultScreen = ({ match, location, history }) => {
  const dispatch = useDispatch();

  const [teacherName, setTeacherName] = useState('');
  const [publishedChecked, setPublishedChecked] = useState(true);

  const parsedQuery = queryString.parse(location.search);

  const evaluationCourseDetails = useSelector(
    (state) => state.evaluationCourseDetails
  );
  const { loading, error, evaluationCourse } = evaluationCourseDetails;

  const evaluationCourseCalculation = useSelector(
    (state) => state.evaluationCourseCalculation
  );
  const { loading: loadingCalculation, error: errorCalculation } =
    evaluationCourseCalculation;

  const evaluationOverallResult = useSelector(
    (state) => state.evaluationOverallResult
  );
  const {
    loading: loadingOverallResult,
    error: errorOverallResult,
    evaluationOverallResult: overallResult,
  } = evaluationOverallResult;

  const evaluationOverallResultDetails = useSelector(
    (state) => state.evaluationOverallResultDetails
  );
  const {
    loading: loadingOverallResultDetails,
    error: errorOverallResultDetails,
    evaluationResult,
  } = evaluationOverallResultDetails;

  const evaluationPerQuesAns = useSelector(
    (state) => state.evaluationPerQuesAns
  );
  const {
    loading: loadingPerQuesResult,
    error: errorPerQuesResult,
    evaluationPerQuesAnsResult,
  } = evaluationPerQuesAns;

  const evaluationPerQuesWrittenAns = useSelector(
    (state) => state.evaluationPerQuesWrittenAns
  );
  const { evaluationPerQuesWrittenAnsResult } = evaluationPerQuesWrittenAns;

  const evaluationResultPublish = useSelector(
    (state) => state.evaluationResultPublish
  );
  const {
    loading: loadingPublish,
    error: errorPublish,
    published,
  } = evaluationResultPublish;

  const evaluationResultPdf = useSelector((state) => state.evaluationResultPdf);
  const { loading: loadingPdf, error: errorPdf, pdf } = evaluationResultPdf;

  const evaluationResultMail = useSelector(
    (state) => state.evaluationResultMail
  );
  const { loading: loadingMail, error: errorMail, mail } = evaluationResultMail;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { user } = userInfo;

  // console.log(evaluationCourse);
  // console.log(evaluationPerQuesAnsResult);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  let values = {
    sem_id: parsedQuery.sem_id,
    course_id: parsedQuery.course_id,
    teacher_id: parsedQuery.teacher_id,
    batch_id: parsedQuery.batch_id,
    program_id: '',
    sortingOrder: '',
    sortBy: '',
  };

  useEffect(
    () => {
      if (pdf) {
        dispatch({ type: EVALUATION_RESULT_PDF_RESET });
      }
      if (mail) {
        dispatch({ type: EVALUATION_RESULT_MAIL_RESET });
      }
      if (
        !evaluationCourse ||
        evaluationCourse.teacher_id != parsedQuery.teacher_id
      ) {
        dispatch(getEvaluationCourseDetails(values));
      } else {
        setTeacherName(evaluationCourse.teacher_name);
      }

      if (
        (!evaluationResult ||
          evaluationResult.teacher_id != parsedQuery.teacher_id) &&
        user.is_admin
      ) {
        dispatch(
          getEvaluationOverallResultDetails(
            parsedQuery.teacher_id,
            parsedQuery.sem_id,
            parsedQuery.course_id,
            parsedQuery.batch_id
          )
        );
      } else {
        if (user.is_admin) setPublishedChecked(evaluationResult.published);
      }

      if (
        (!evaluationPerQuesAnsResult ||
          evaluationPerQuesAnsResult[0].teacher_id != parsedQuery.teacher_id) &&
        user.is_admin
      ) {
        dispatch(
          getEvaluationPerQuesResult(
            parsedQuery.teacher_id,
            parsedQuery.sem_id,
            parsedQuery.course_id,
            parsedQuery.batch_id
          )
        );
      }
      if (
        (!evaluationPerQuesWrittenAnsResult ||
          evaluationPerQuesWrittenAnsResult[0].teacher_id !=
            parsedQuery.teacher_id) &&
        user.is_admin
      ) {
        dispatch(
          getEvaluationPerQuesWrittenResult(
            parsedQuery.teacher_id,
            parsedQuery.sem_id,
            parsedQuery.course_id,
            parsedQuery.batch_id
          )
        );
      }
    },
    [
      // dispatch,
      // evaluationCourse,
      // evaluationResult,
      // evaluationPerQuesAnsResult,
      // evaluationPerQuesWrittenAnsResult,
      // parsedQuery.teacher_id,
    ]
  );

  // useEffect(() => {
  //   if (pdf) {
  //     const pdfBlob = new Blob([pdf], { type: 'application/pdf' });
  //     saveAs(pdfBlob, 'newPdf.pdf');
  //   }
  // }, [pdf]);

  const onResultCalculate = () => {
    dispatch(evaluationCourseCalculate(values));
  };

  const onEmail = (id) => {
    dispatch(mailEvaluationResult(id));
  };

  const onPdf = (id, teacher_name, course_name, sem_name, sem_year, save) => {
    dispatch(
      pdfEvaluationResult(
        id,
        teacher_name,
        course_name,
        sem_name,
        sem_year,
        save
      )
    );
  };

  const handlePublishedChange = (e, id) => {
    setPublishedChecked(e.target.checked);
    dispatch(publishEvaluationResult(id, e.target.checked));
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumb
            linkText={history.location.pathname}
            lastLink={teacherName}
          />
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          <>
            {evaluationCourse && (
              <Grid item xs={12}>
                <Meta
                  title={evaluationCourse.teacher_name}
                  description={`${evaluationCourse.teacher_name}, ${evaluationCourse.course_name}, ${evaluationCourse.batch_name}, ${evaluationCourse.program_name}, ${evaluationCourse.sem_name}`}
                  keywords={`${evaluationCourse.teacher_name}, ${evaluationCourse.course_name}, ${evaluationCourse.batch_name}, ${evaluationCourse.program_name}, ${evaluationCourse.sem_name}`}
                />
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 12 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {evaluationCourse.program_name}
                    </Typography>
                    <Typography variant="h5" component="div">
                      {evaluationCourse.course_name}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Code: {evaluationCourse.course_code}, Credit:{' '}
                      {evaluationCourse?.course_credit}, Semester:{' '}
                      {evaluationCourse.sem_name}
                    </Typography>
                    <Typography variant="body2">
                      Faculty: {evaluationCourse.teacher_name} <br /> Batch:{' '}
                      {evaluationCourse.batch_name}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Link to={'/evaluation'}>
                      <Button size="medium" disableElevation>
                        Back
                      </Button>
                    </Link>
                    {/* {evaluationCourse?.hasevaluated ? (
                      <Button size="medium" disabled>
                        Evaluated
                      </Button>
                    ) : (
                      <Button size="medium" onClick={onResultCalculate}>
                        Calculate
                        {loadingCalculation && (
                          <>
                            &nbsp;
                            <CircularProgress size={15} thickness={5} />
                          </>
                        )}
                      </Button>
                    )} */}
                    {user.is_admin ? (
                      <Button size="medium" onClick={onResultCalculate}>
                        Calculate
                        {loadingCalculation && (
                          <>
                            &nbsp;
                            <CircularProgress size={15} thickness={5} />
                          </>
                        )}
                      </Button>
                    ) : null}
                  </CardActions>
                </Card>
              </Grid>
            )}
            {evaluationCourse && (
              <Grid item xs={12}>
                <EvaluationAdminStudentResultScreen
                  evaluationResult={evaluationResult}
                />
              </Grid>
            )}

            {evaluationResult && user.is_admin && (
              <Grid item xs={12}>
                <TableContainer component={Paper} variant="outlined">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={2}>
                          Total Evaluation Result
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Strongly Agree</TableCell>
                        <TableCell>
                          {evaluationResult.strongly_agree}%
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Agree</TableCell>
                        <TableCell>{evaluationResult.agree}%</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Neutral</TableCell>
                        <TableCell>{evaluationResult.neutral}%</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Disagree</TableCell>
                        <TableCell>{evaluationResult.disagree}%</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Strongly Disagree</TableCell>
                        <TableCell>
                          {evaluationResult.strongly_disagree}%
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          Enrolled: {evaluationResult.total_enrolled}, &nbsp;
                          Evaluated: {evaluationResult.total_evaluated}
                        </TableCell>
                      </TableRow>

                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={2}>
                          <FormControlLabel
                            label="Publish"
                            disabled={loadingPublish}
                            control={
                              <Checkbox
                                checked={publishedChecked}
                                onChange={(e) =>
                                  handlePublishedChange(e, evaluationResult.id)
                                }
                              />
                            }
                          />
                          <Button
                            size="medium"
                            onClick={(e) => onEmail(evaluationResult.id)}
                            disabled={!evaluationResult.published}
                          >
                            Email Report
                            {loadingMail && (
                              <>
                                &nbsp;
                                <CircularProgress size={15} thickness={5} />
                              </>
                            )}
                          </Button>
                          <Button
                            size="medium"
                            disabled={loadingPdf}
                            onClick={(e) =>
                              onPdf(
                                evaluationResult.id,
                                evaluationResult.teacher_name,
                                evaluationResult.course_name,
                                evaluationResult.sem_name,
                                evaluationResult.sem_year
                              )
                            }
                          >
                            View PDF Report
                          </Button>
                          <Button
                            size="medium"
                            disabled={loadingPdf}
                            onClick={(e) =>
                              onPdf(
                                evaluationResult.id,
                                evaluationResult.teacher_name,
                                evaluationResult.course_name,
                                evaluationResult.sem_name,
                                evaluationResult.sem_year,
                                true
                              )
                            }
                          >
                            Download PDF Report
                          </Button>
                          {evaluationPerQuesAnsResult &&
                            evaluationPerQuesWrittenAnsResult && (
                              <PerQuesCsvDownloader
                                details={evaluationCourse}
                                overallResult={evaluationResult}
                                results={evaluationPerQuesAnsResult}
                                writtenResults={
                                  evaluationPerQuesWrittenAnsResult
                                }
                              />
                            )}
                          {loadingPdf && (
                            <Box sx={{ width: '100%' }}>
                              <LinearProgress />
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            {evaluationCourse &&
              evaluationPerQuesAnsResult &&
              evaluationPerQuesWrittenAnsResult &&
              user.is_admin && (
                <Grid item xs={12}>
                  <EvaluationReport
                    mcqData={evaluationPerQuesAnsResult}
                    overallMcqData={evaluationResult}
                    writtenData={evaluationPerQuesWrittenAnsResult}
                    courseDetails={evaluationCourse}
                  />
                  <br />
                  <EvaluationTeacherResultScreen
                    evaluationPerQuesAnsResult={evaluationPerQuesAnsResult}
                  />
                </Grid>
              )}
          </>
        )}
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default EvaluationAdminTeacherResultScreen;
