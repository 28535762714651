import { IconButton } from '@mui/material';
import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuid } from 'uuid';

const CourseBulkTable = ({
  csvArray,
  handleChangeBulk,
  handleAddBulkRow,
  handleRemoveBulkRow,
}) => {
  return (
    <table className="table-style">
      <thead>
        <th className="table-header">Course Name</th>
        <th className="table-header">Code</th>
        <th className="table-header">Type</th>
        <th className="table-header">Credit</th>
      </thead>
      <tbody>
        {csvArray.map((item, index) => (
          <tr key={index} className="table-row">
            <td className="table-data">
              <input
                type="text"
                name="course_name"
                className="input-style"
                value={item.course_name}
                onChange={(e) => handleChangeBulk(e, item.id)}
              />
            </td>
            <td className="table-data">
              <input
                type="text"
                name="course_code"
                className="input-style"
                value={item.course_code}
                onChange={(e) => handleChangeBulk(e, item.id)}
              />
            </td>
            <td className="table-data">
              <input
                type="text"
                name="course_type"
                className="input-style"
                value={item.course_type}
                onChange={(e) => handleChangeBulk(e, item.id)}
              />
            </td>
            <td className="table-data">
              <input
                type="number"
                name="course_credit"
                className="input-style"
                value={item.course_credit}
                onChange={(e) => handleChangeBulk(e, item.id)}
              />
            </td>
            <td className="table-data">
              <IconButton
                disabled={item.length === 1}
                onClick={() => handleRemoveBulkRow(item.id)}
                color="danger"
              >
                <CancelIcon />
              </IconButton>
              {csvArray.length - 1 === index && (
                <IconButton
                  onClick={() => handleAddBulkRow(uuid())}
                  color="primary"
                >
                  <AddCircleIcon />
                </IconButton>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CourseBulkTable;
