import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import StyledTableCell from '../StyledTableCell';
import NDUB_LOGO from '../../img/NDUB_Logo.png';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const EvaluationReport = ({
  mcqData,
  overallMcqData,
  writtenData,
  courseDetails,
}) => {
  // useEffect(() => {
  //   // const doc = new jsPDF('p', 'pt', 'a4');
  //   // var content = document.getElementById('pdfContent');
  //   // doc.html(content, {
  //   //   callback: function (doc) {
  //   //     doc.save();
  //   //     // doc.output('dataurlnewwindow');
  //   //   },
  //   //   //   x: 10,
  //   //   //   y: 10,
  //   // });

  //   function printDocument() {
  //     const input = document.getElementById('pdfContent');
  //     html2canvas(input).then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF();
  //       pdf.addImage(imgData, 'JPEG', 0, 0);
  //       // pdf.output('dataurlnewwindow');
  //       pdf.save('download.pdf');
  //     });
  //   }
  //   printDocument();
  // }, []);

  //   doc.text('Hello world!', 10, 10);
  //   doc.save('a4.pdf');

  // <div>content</div>

  // const exportPDF = () => {
  //   savePDF()
  //   pdfExportComponent.save();
  // };

  const pdfExportComponent = useRef(null);
  const contentArea = useRef(null);

  const handleExportWithComponent = (event) => {
    pdfExportComponent.current.save();
  };

  const handleExportWithMethod = (event) => {
    savePDF(contentArea.current, { paperSize: 'A4' });
  };

  const currentTime = new Date();

  return (
    <div>
      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>PDF Report (Version 2)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            size="small"
            variant="contained"
            onClick={handleExportWithComponent}
            style={{ marginBottom: '2rem' }}
            disableElevation
          >
            Download{' '}
            <FileDownloadIcon
              style={{ fontSize: '1.2rem', marginLeft: '2px' }}
            />
          </Button>
          <PDFExport
            paperSize={'A4'}
            fileName={`${courseDetails?.teacher_name}_${
              courseDetails?.course_name
            }_${courseDetails?.sem_name}_${
              courseDetails?.sem_year
            }_${currentTime.toLocaleTimeString()}.pdf`}
            margin={10}
            title=""
            subject=""
            keywords=""
            ref={pdfExportComponent}
          >
            <div ref={contentArea}>
              <Grid container id="pdfContent">
                <Grid
                  item
                  xs={3}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={NDUB_LOGO}
                    alt="NDUB Logo"
                    className="login-art"
                    style={{ width: '110px', height: '110px' }}
                  />
                </Grid>
                <Grid item xs={9} style={{ textAlign: 'center' }}>
                  <Typography
                    variant="h4"
                    component="div"
                    style={{ fontSize: '16px', textAlign: 'center' }}
                  >
                    NOTRE DAME UNIVERSITY BANGLADESH
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{ fontSize: '14px', textAlign: 'center' }}
                  >
                    Faculty Evaluation by the students
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{ fontSize: '14px', textAlign: 'center' }}
                  >
                    {courseDetails?.teacher_name}
                  </Typography>
                  <Typography
                    variant="p"
                    component="div"
                    style={{ fontSize: '12px', textAlign: 'center' }}
                  >
                    {courseDetails?.program_name}
                  </Typography>
                  <Typography
                    variant="p"
                    component="div"
                    style={{ fontSize: '12px', textAlign: 'center' }}
                  >
                    {`${courseDetails?.course_name}(${courseDetails?.course_code})`}
                  </Typography>
                  <Typography
                    variant="p"
                    component="div"
                    style={{ fontSize: '12px', textAlign: 'center' }}
                  >
                    {courseDetails?.batch_name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: 'center', textAlign: 'center' }}
                >
                  <Typography
                    variant="overline"
                    component="div"
                    gutterBottom
                    style={{ fontSize: '8px' }}
                  >
                    SA = Strongly Agree, A = Agree, N = Neutral, D = Disagree,
                    SD = Strongly Disagree
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer component={Paper} variant="outlined">
                    <Table size="small" style={{ tableLayout: 'fixed' }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            align="center"
                            style={{ fontSize: '10px', fontWeight: 'bold' }}
                            width="5px"
                          >
                            SL
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ fontSize: '10px', fontWeight: 'bold' }}
                            width="200px"
                          >
                            Questionnaire
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontSize: '10px', fontWeight: 'bold' }}
                            width="30px"
                          >
                            SA
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontSize: '10px', fontWeight: 'bold' }}
                            width="30px"
                          >
                            A
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontSize: '10px', fontWeight: 'bold' }}
                            width="30px"
                          >
                            N
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontSize: '10px', fontWeight: 'bold' }}
                            width="30px"
                          >
                            D
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontSize: '10px', fontWeight: 'bold' }}
                            width="30px"
                          >
                            SD
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mcqData &&
                          mcqData.map((mcq, index) => (
                            <TableRow
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                              key={index.toString()}
                            >
                              <TableCell
                                align="center"
                                style={{ fontSize: '10px' }}
                                width="5px"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ fontSize: '10px' }}
                                width="200px"
                              >
                                {mcq.mcq_ques_en}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: '10px' }}
                                width="30px"
                              >
                                {mcq.strongly_agree}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: '10px' }}
                                width="30px"
                              >
                                {mcq.agree}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: '10px' }}
                                width="30px"
                              >
                                {mcq.neutral}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: '10px' }}
                                width="30px"
                              >
                                {mcq.disagree}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: '10px' }}
                                width="30px"
                              >
                                {mcq.strongly_disagree}
                              </TableCell>
                            </TableRow>
                          ))}
                        {overallMcqData && (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              style={{ fontSize: '10px' }}
                            >
                              Total average percentage of the report
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: '10px' }}
                            >
                              {overallMcqData.strongly_agree}%
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: '10px' }}
                            >
                              {overallMcqData.agree}%
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: '10px' }}
                            >
                              {overallMcqData.neutral}%
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: '10px' }}
                            >
                              {overallMcqData.disagree}%
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: '10px' }}
                            >
                              {overallMcqData.strongly_disagree}%
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer
                    component={Paper}
                    variant="outlined"
                    style={{ marginTop: '1rem', marginBottom: '-5px' }}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            align="center"
                            style={{ fontSize: '10px', fontWeight: 'bold' }}
                          >
                            Written
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  <TableContainer component={Paper} variant="outlined">
                    <Table size="small" style={{ tableLayout: 'fixed' }}>
                      <TableBody>
                        {writtenData &&
                          writtenData.map((written, index) => (
                            <TableRow
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                              key={index.toString()}
                            >
                              <TableCell
                                align="left"
                                style={{ fontSize: '10px' }}
                                width="5px"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ fontSize: '10px' }}
                                width="350px"
                              >
                                {written.written_ques_en}

                                {written.written_ans &&
                                  written.written_ans.map((ans, i) => (
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      style={{ fontSize: '8px' }}
                                      key={i.toString()}
                                    >
                                      {ans}
                                    </Typography>
                                  ))}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={6} style={{ marginTop: '4rem' }}>
                  <Divider />
                  <Typography
                    variant="body1"
                    display="block"
                    style={{ marginTop: '1rem', fontSize: '14px' }}
                  >
                    Dr. Fr. Leonard Shankar Rozario, CSC
                  </Typography>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ fontSize: '10px' }}
                  >
                    Registrar In Charge
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    style={{ fontSize: '10px' }}
                  >
                    Notre Dame University Bangladesh
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ marginTop: '4rem' }}>
                  <Divider />
                  <Typography
                    variant="body1"
                    display="block"
                    style={{ marginTop: '1rem', fontSize: '14px' }}
                  >
                    Fr. Patrick Daniel Gaffney, CSC
                  </Typography>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ fontSize: '10px' }}
                  >
                    Vice Chancellor
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    style={{ fontSize: '10px' }}
                  >
                    Notre Dame University Bangladesh
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </PDFExport>
        </AccordionDetails>
      </Accordion>

      {/* <button onClick={handleExportWithComponent}>Compo</button> */}
      {/* <button onClick={handleExportWithMethod}>Method</button> */}
    </div>
  );
};

export default EvaluationReport;
