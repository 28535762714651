import axios from 'axios';
import {
  MAIL_SEND_FAIL,
  MAIL_SEND_REQUEST,
  MAIL_SEND_SUCCESS,
} from '../constants/mailConstants';
import { setAlert } from './alertActions';

// Send single email
export const sendMail = (values) => async (dispatch) => {
  try {
    dispatch({ type: MAIL_SEND_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(`/api/mail`, values, config);

    dispatch({
      type: MAIL_SEND_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data, 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: MAIL_SEND_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const sendMultiMail = (values) => async (dispatch) => {
  try {
    dispatch({ type: MAIL_SEND_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(`/api/mail/multi`, values, config);

    dispatch({
      type: MAIL_SEND_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data, 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: MAIL_SEND_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
