import {
  PROGRAM_LIST_REQUEST,
  PROGRAM_LIST_SUCCESS,
  PROGRAM_LIST_FAIL,
  PROGRAM_LIST_RESET,
  PROGRAM_LIST_REMOVE_ITEM,
  PROGRAM_DELETE_REQUEST,
  PROGRAM_DELETE_SUCCESS,
  PROGRAM_DELETE_FAIL,
  PROGRAM_DELETE_RESET,
  PROGRAM_ADD_FAIL,
  PROGRAM_ADD_SUCCESS,
  PROGRAM_ADD_REQUEST,
  PROGRAM_EDIT_FAIL,
  PROGRAM_EDIT_SUCCESS,
  PROGRAM_EDIT_REQUEST,
  PROGRAM_DETAILS_FAIL,
  PROGRAM_DETAILS_SUCCESS,
  PROGRAM_DETAILS_REQUEST,
  PROGRAM_DETAILS_RESET,
  PROGRAM_EDIT_RESET,
  PROGRAM_ADD_RESET,
} from '../constants/programConstants';

export const programListReducer = (state = {}, action) => {
  switch (action.type) {
    case PROGRAM_LIST_REQUEST:
      return { loading: true };
    case PROGRAM_LIST_SUCCESS:
      return {
        loading: false,
        programs: action.payload,
      };
    case PROGRAM_LIST_REMOVE_ITEM:
      return {
        ...state,
        programs: state.programs.filter(
          (program) => program.program_id !== action.payload
        ),
      };
    case PROGRAM_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PROGRAM_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const programDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PROGRAM_DETAILS_REQUEST:
      return { loading: true };
    case PROGRAM_DETAILS_SUCCESS:
      return {
        loading: false,
        program: action.payload,
      };
    case PROGRAM_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PROGRAM_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const programEditReducer = (state = {}, action) => {
  switch (action.type) {
    case PROGRAM_EDIT_REQUEST:
      return { loading: true };
    case PROGRAM_EDIT_SUCCESS:
      return {
        loading: false,
        program: action.payload,
        success: true,
      };
    case PROGRAM_EDIT_FAIL:
      return { loading: false, error: action.payload, success: false };
    case PROGRAM_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const programAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PROGRAM_ADD_REQUEST:
      return { loading: true };
    case PROGRAM_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        program: action.payload,
      };
    case PROGRAM_ADD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case PROGRAM_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const programDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PROGRAM_DELETE_REQUEST:
      return { loading: true };
    case PROGRAM_DELETE_SUCCESS:
      return {
        loading: false,
        program: action.payload,
      };
    case PROGRAM_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PROGRAM_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
