import {
  SEMESTER_LIST_REQUEST,
  SEMESTER_LIST_SUCCESS,
  SEMESTER_LIST_FAIL,
  SEMESTER_LIST_RESET,
  SEMESTER_LIST_REMOVE_ITEM,
  SEMESTER_DETAILS_REQUEST,
  SEMESTER_DETAILS_SUCCESS,
  SEMESTER_DETAILS_FAIL,
  SEMESTER_DETAILS_RESET,
  SEMESTER_EDIT_REQUEST,
  SEMESTER_EDIT_SUCCESS,
  SEMESTER_EDIT_FAIL,
  SEMESTER_EDIT_RESET,
  SEMESTER_ADD_REQUEST,
  SEMESTER_ADD_FAIL,
  SEMESTER_ADD_SUCCESS,
  SEMESTER_ADD_RESET,
  SEMESTER_DELETE_REQUEST,
  SEMESTER_DELETE_SUCCESS,
  SEMESTER_DELETE_FAIL,
  SEMESTER_DELETE_RESET,
} from '../constants/semesterConstants';

export const semesterListReducer = (state = {}, action) => {
  switch (action.type) {
    case SEMESTER_LIST_REQUEST:
      return { loading: true };
    case SEMESTER_LIST_SUCCESS:
      return {
        loading: false,
        semesters: action.payload,
      };
    case SEMESTER_LIST_REMOVE_ITEM:
      return {
        ...state,
        semesters: state.semesters.filter(
          (semester) => semester.sem_id !== action.payload
        ),
      };
    case SEMESTER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case SEMESTER_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const semesterDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case SEMESTER_DETAILS_REQUEST:
      return { loading: true };
    case SEMESTER_DETAILS_SUCCESS:
      return {
        loading: false,
        semester: action.payload,
      };
    case SEMESTER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case SEMESTER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const semesterEditReducer = (state = {}, action) => {
  switch (action.type) {
    case SEMESTER_EDIT_REQUEST:
      return { loading: true };
    case SEMESTER_EDIT_SUCCESS:
      return {
        loading: false,
        semester: action.payload,
        success: true,
      };
    case SEMESTER_EDIT_FAIL:
      return { loading: false, error: action.payload, success: false };
    case SEMESTER_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const semesterAddReducer = (state = {}, action) => {
  switch (action.type) {
    case SEMESTER_ADD_REQUEST:
      return { loading: true };
    case SEMESTER_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        semester: action.payload,
      };
    case SEMESTER_ADD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case SEMESTER_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const semesterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SEMESTER_DELETE_REQUEST:
      return { loading: true };
    case SEMESTER_DELETE_SUCCESS:
      return {
        loading: false,
        semester: action.payload,
      };
    case SEMESTER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case SEMESTER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
