export const FACULTY_LIST_REQUEST = 'FACULTY_LIST_REQUEST';
export const FACULTY_LIST_SUCCESS = 'FACULTY_LIST_SUCCESS';
export const FACULTY_LIST_FAIL = 'FACULTY_LIST_FAIL';
export const FACULTY_LIST_RESET = 'FACULTY_LIST_RESET';
export const FACULTY_LIST_REMOVE_ITEM = 'FACULTY_LIST_REMOVE_ITEM';

export const FACULTY_DETAILS_REQUEST = 'FACULTY_DETAILS_REQUEST';
export const FACULTY_DETAILS_SUCCESS = 'FACULTY_DETAILS_SUCCESS';
export const FACULTY_DETAILS_FAIL = 'FACULTY_DETAILS_FAIL';
export const FACULTY_DETAILS_RESET = 'FACULTY_DETAILS_RESET';

export const FACULTY_EDIT_REQUEST = 'FACULTY_EDIT_REQUEST';
export const FACULTY_EDIT_SUCCESS = 'FACULTY_EDIT_SUCCESS';
export const FACULTY_EDIT_FAIL = 'FACULTY_EDIT_FAIL';
export const FACULTY_EDIT_RESET = 'FACULTY_EDIT_RESET';

export const FACULTY_ADD_REQUEST = 'FACULTY_ADD_REQUEST';
export const FACULTY_ADD_SUCCESS = 'FACULTY_ADD_SUCCESS';
export const FACULTY_ADD_FAIL = 'FACULTY_ADD_FAIL';
export const FACULTY_ADD_RESET = 'FACULTY_ADD_RESET';

export const FACULTY_DELETE_REQUEST = 'FACULTY_DELETE_REQUEST';
export const FACULTY_DELETE_SUCCESS = 'FACULTY_DELETE_SUCCESS';
export const FACULTY_DELETE_FAIL = 'FACULTY_DELETE_FAIL';
export const FACULTY_DELETE_RESET = 'FACULTY_DELETE_RESET';
