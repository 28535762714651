import {
  EVAL_ANS_SUBMIT_FAIL,
  EVAL_ANS_SUBMIT_REQUEST,
  EVAL_ANS_SUBMIT_RESET,
  EVAL_ANS_SUBMIT_SUCCESS,
} from '../constants/evalAnsConstants';

export const evalAnsSubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case EVAL_ANS_SUBMIT_REQUEST:
      return { loading: true };
    case EVAL_ANS_SUBMIT_SUCCESS:
      return {
        loading: false,
        evaluationAnswers: action.payload,
        success: true,
      };
    case EVAL_ANS_SUBMIT_FAIL:
      return { loading: false, error: action.payload, success: false };
    case EVAL_ANS_SUBMIT_RESET:
      return {};
    default:
      return state;
  }
};
