import {
  Button,
  Card,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';

import NDUB_LOGO from '../img/NDUB_Logo.png';
import { Link } from 'react-router-dom';
import { login } from '../actions/userActions';

import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Alert from '../components/Alert';
import setAuthToken from '../utils/setAuthToken';
import { REMOVE_ALERT } from '../constants/alertConstants';
import {
  USER_EMAIL_VERIFY_RESET,
  USER_LOGIN_RESET,
  USER_PASSWORD_RESET_RESET,
  USER_PASSWORD_RESET_VERIFY_CODE_RESET,
  USER_PASSWORD_RESET_VERIFY_EMAIL_RESET,
  USER_REGISTER_RESET,
} from '../constants/userConstants';

import Box from '@mui/material/Box';
import { Alert as Alert2 } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const LoginScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    email: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [open, setOpen] = React.useState(true);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo: userInfoRegister } = userRegister;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    dispatch({ type: USER_LOGIN_RESET });
    dispatch({ type: USER_REGISTER_RESET });
    dispatch({ type: USER_PASSWORD_RESET_RESET });
    dispatch({ type: USER_PASSWORD_RESET_VERIFY_CODE_RESET });
    dispatch({ type: USER_PASSWORD_RESET_VERIFY_EMAIL_RESET });
    dispatch({ type: USER_EMAIL_VERIFY_RESET });
  }, []);

  useEffect(() => {
    if (msg) {
      dispatch({ type: REMOVE_ALERT });
    }
    if (userInfo) {
      setAuthToken(userInfo.token);
      history.push('/evaluation');
    }

    if (userInfoRegister) {
      history.push(`/register/verify?token=${userInfoRegister.token}`);
    }
  }, [history, userInfo, userInfoRegister]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login(values.email.trim(), values.password));
  };

  return (
    <Container>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="login-container"
      >
        <Grid
          item
          xs={12}
          sm={6}
          className="login-art-container"
          direction={'column'}
        >
          <img src={NDUB_LOGO} alt="NDUB Logo" className="login-art" />
          <h2 className="primary-header">NDUB Student Portal</h2>
        </Grid>
        <Grid item xs={12} sm={6} className="grid-center">
          <Card variant="outlined" className="login-form-container">
            <h2 className="primary-header">Login</h2>
            {/* <Chip
              label="You must register before logging in to the student portal"
              color="primary"
            /> */}

            <Box sx={{ width: '100%', marginTop: '10px' }}>
              <Collapse in={open}>
                <Alert2
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  If you do not have an account please{' '}
                  <Link
                    to="/register"
                    style={{ textDecoration: 'underline', fontWeight: '600' }}
                  >
                    register
                  </Link>{' '}
                  here
                </Alert2>
              </Collapse>
            </Box>

            {/* <p
              style={{
                color: 'white',
                textAlign: 'center',
                background: 'red',
                padding: '10px',
                borderRadius: '50px',
                animation: 'fadeIn linear 2s',
              }}
            >
              You must{' '}
              <Link
                to="/register"
                style={{ textDecoration: 'underline', fontWeight: '600' }}
              >
                register
              </Link>{' '}
              before logging in to the student portal
            </p> */}
            <form onSubmit={(e) => onSubmit(e)}>
              <TextField
                variant="outlined"
                type="text"
                value={values.email}
                onChange={handleChange('email')}
                label="Email"
                style={{ width: '100%', marginBottom: '20px' }}
              />

              <TextField
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Password"
              />

              <Button
                variant="contained"
                disableElevation
                size="large"
                style={{ width: '100%', marginBottom: '20px' }}
                type="submit"
              >
                Login{' '}
                {loading && (
                  <>
                    &nbsp;
                    <CircularProgress color="white" size={20} thickness={5} />
                  </>
                )}
              </Button>
            </form>
            <Typography variant="p">
              Don't have an account?{' '}
              <Link to="/register" style={{ textDecoration: 'underline' }}>
                Register
              </Link>{' '}
              | Forgot password?{' '}
              <Link to="/reset" style={{ textDecoration: 'underline' }}>
                Reset
              </Link>{' '}
              | Need help?{' '}
              <Link to="/help" style={{ textDecoration: 'underline' }}>
                Help
              </Link>
            </Typography>
          </Card>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default LoginScreen;
