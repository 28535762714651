import {
  MAIL_SEND_FAIL,
  MAIL_SEND_REQUEST,
  MAIL_SEND_RESET,
  MAIL_SEND_SUCCESS,
} from '../constants/mailConstants';

export const mailSendReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIL_SEND_REQUEST:
      return { loading: true };
    case MAIL_SEND_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case MAIL_SEND_FAIL:
      return { loading: false, success: false, error: action.payload };
    case MAIL_SEND_RESET:
      return {};
    default:
      return state;
  }
};
