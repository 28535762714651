import React from 'react';
import { useSelector } from 'react-redux';
import PasswordChangePopup from '../../components/popups/PasswordChangePopup';

const ProfileDetailsScreen = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  return (
    <div>
      <h2 className="primary-header">Profile Details</h2>
      <table>
        <tr>
          <td>Name</td>
          <td>:</td>
          <td>{userInfo.user.name}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>:</td>
          <td>{userInfo.user.user_email}</td>
        </tr>
        {userInfo.user.is_student && (
          <>
            <tr>
              <td>Student ID</td>
              <td>:</td>
              <td>{userInfo.student.stu_id}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>:</td>
              <td>{userInfo.student.stu_mobile_number}</td>
            </tr>
          </>
        )}
        {userInfo.user.is_faculty && (
          <>
            <tr>
              <td>Designation</td>
              <td>:</td>
              <td>{userInfo.teacher.teacher_designation}</td>
            </tr>
          </>
        )}
      </table>
      <PasswordChangePopup disabled={false} />
    </div>
  );
};

export default ProfileDetailsScreen;
